import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GridColumn, GridComponent } from '../../shared/components/grid/grid.component';
import { FadeInDirective } from '../../shared/directives/fade-in.directive';
import { TranslatePipe } from '../../shared/translations/translate.pipe';
import { Notification } from '../../core/services/notification.service';
import { AuthService, TenantDtoGridData, TenantService, UserDto } from '../../shared/generated';
import { Observable, Subscription, forkJoin, tap } from 'rxjs';
import { DialogService } from '../../core/services/dialog.service';
import { getDefaultGridState, DefaultPageSettings } from '../../shared/helpers/grid.helper';
import { Translation } from '../../shared/translations/translate.service';

@Component({
	selector: 'app-users',
	standalone: true,
	imports: [CommonModule, GridComponent, RouterModule, TranslatePipe, FadeInDirective],
	templateUrl: './users.component.html',
	styleUrl: './users.component.scss'
})
export class UsersComponent implements OnInit, OnDestroy {
	public isLoading: boolean;
	public state = getDefaultGridState();
	public pagerSettings = DefaultPageSettings;
	public users$: Observable<TenantDtoGridData>;
	public gridColumns: GridColumn[] = [
		{ field: 'firstName', content: Translation.getResource('admin.userFirstName'), sortable: true, filter: 'text' },
		{ field: 'lastName', content: Translation.getResource('admin.userLastName'), sortable: true, filter: 'text' },
		{ field: 'email', content: Translation.getResource('admin.userEmail'), sortable: true, filter: 'text' },
		{ field: 'role', content: Translation.getResource('admin.userRole'), sortable: true, filter: 'dropdown', optionValue: 'name' },
		{ field: 'products', content: Translation.getResource('admin.products'), sortable: true, filter: 'dropdown', optionValue: 'name' },
		{ field: 'tenant', content: Translation.getResource('admin.userTenant'), sortable: true, filter: 'dropdown', optionValue: 'name' },
		{ field: 'emailConfirmed', content: Translation.getResource('admin.userConfirmed'), sortable: true, filter: 'boolean' },
		{ field: 'active', content: Translation.getResource('admin.active'), sortable: true, filter: 'boolean' }
	];

	private subscriptions: Subscription[] = [];

	constructor(private authService: AuthService, private tenantService: TenantService) { }

	public ngOnInit() {
		this.getDropdownData();
		this.getData();
	}

	public dataStateChange(state): void {
		this.state = state;
		this.getData();
	}

	private getDropdownData() {
		this.subscriptions.push(
			forkJoin([
				this.tenantService.getAllTenants(),
				this.authService.getAllRoles()
			]).subscribe(([tenants, roles]) => {
				this.gridColumns[3].options = roles;
				this.gridColumns[4].options = tenants;
			})
		);
	}

	private getData(): void {
		this.isLoading = true;
		this.users$ = this.authService.getAllUsersForGrid(0, this.state).pipe(tap({ next: () => this.isLoading = false, error: () => this.isLoading = false }));
	}

	public onDelete(user: UserDto) {
		DialogService.areYouSureDialog(
			() => this.deleteUser(user.id),
			null,
			Translation.getResource('admin.userDeleteConfirm'),
			Translation.getResource('general.delete')
		);
	}

	private deleteUser(id: number) {
		this.subscriptions.push(
			this.authService.deleteUser({ id, productId: 0 }).subscribe(() => {
				Notification.showSuccess(Translation.getResource('admin.userDeleteSuccess'));
				this.getData();
			})
		);
	}

	public onReactivate(user: UserDto) {
		DialogService.areYouSureDialog(
			() => this.reactivateUser(user.id),
			null,
			Translation.getResource('admin.userReactivateConfirm'),
			Translation.getResource('general.reactivate')
		);
	}

	private reactivateUser(id: number) {
		this.subscriptions.push(
			this.authService.reactivateUser({ id }).subscribe(() => {
				Notification.showSuccess(Translation.getResource('admin.userReactivateSuccess'));
				this.getData();
			})
		);
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}
}
