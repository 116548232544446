<div appFadeIn class="row g-3">
	<div class="col-md-4">
		<div class="card">
			<div class="card-header">
				Tenants
			</div>
			<div class="card-body">
				<div class="d-flex flex-column">
					<button class="btn btn-outline-default text-start fw-bold mb-2" routerLink="/admin/tenants">
						<span class="badge rounded-pill bg-app p-3 me-3"><i class="fas fa-plus"></i></span> View Tenants
					</button>
					<button class="btn btn-outline-default text-start fw-bold">
						<span class="badge rounded-pill bg-app p-3 me-3"><i class="fas fa-plus"></i></span> Add New Tenant
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-4">
		<div class="card">
			<div class="card-header">
				Products
			</div>
			<div class="card-body">
				<div class="d-flex flex-column">
					<button class="btn btn-outline-default text-start fw-bold mb-2" routerLink="/admin/products">
						<span class="badge rounded-pill bg-app p-3 me-3"><i class="fas fa-plus"></i></span> View Products
					</button>
					<button class="btn btn-outline-default text-start fw-bold">
						<span class="badge rounded-pill bg-app p-3 me-3"><i class="fas fa-plus"></i></span> Add New Product
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-4">
		<div class="card">
			<div class="card-header">
				Users
			</div>
			<div class="card-body">
				<div class="d-flex flex-column">
					<button class="btn btn-outline-default text-start fw-bold mb-2" routerLink="/admin/users">
						<span class="badge rounded-pill bg-app p-3 me-3"><i class="fas fa-plus"></i></span> View Users
					</button>
					<button class="btn btn-outline-default text-start fw-bold">
						<span class="badge rounded-pill bg-app p-3 me-3"><i class="fas fa-plus"></i></span> Add New User
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-8">
		<div class="card">
			<div class="card-header">
				Product sales
			</div>
			<div class="card-body">
				<p-chart type="bar" [data]="data" [options]="options"></p-chart>
			</div>
		</div>
	</div>
	<div class="col-md-4">
		<div class="card">
			<div class="card-header">
				Monthly earnings
			</div>
			<div class="card-body">
				<p-chart type="pie" [data]="data" [options]="options"></p-chart>
			</div>
		</div>
	</div>
</div>
