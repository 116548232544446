<!-- <div class="intro" id="intro">
    <h1 class="logo-header">
        <span class="animation-logo"><img src="../../assets/img/reAuthLogo.png" class="animation-logo-image" title="Re.Auth"></span>
        <span class="animation-logo">Re</span>
        <span class="animation-logo">.</span>
        <span class="animation-logo" [hidden]="!showLogoutMsg">Auth</span>
        <span class="animation-logo" [hidden]="showLogoutMsg">Goodbye</span>
        <span class="animation-logo" [hidden]="showLogoutMsg">!</span>
    </h1>
</div> -->

<div class="background"></div>
<div class="header">
    <div class="login-container">
        <ul>
            <li>
                <a class="logo">
                    <div class="images">
                        <img src="../../assets/img/reAuthLogo.png" class="logo-forDark" title="Re.Auth">
                        <img src="../../assets/img/reAuthLogo.png" class="logo-forLight" title="Re.Auth">
                    </div>
                    <h2>Re<span>.</span>Auth</h2>
                </a>
            </li>
        </ul>
    </div>
</div>
<div class="main-wrapper">
    <section class="contact">
        <div class="login-container">
            <div class="left">
                <div class="form-wrapper">
                    <div class="contact-heading">
                        <div class="type-container">
                        </div>
                        <div class="type-container">
                            <div class="typed-out-title">{{'login.title1' | translate}}</div>
                        </div>
                        <div class="type-container">
                            <div *ngIf="showProductDropdown" class="typed-out-subtitle">{{'login.title2' | translate}}</div>
                            <div *ngIf="!showProductDropdown" class="typed-out-subtitle">{{'login.title3' | translate}}</div>
                        </div>
                    </div>
                    <div class="contact-form">
                        <div class="py-1" *ngIf="!availableTenants">	
							<span *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</span>

							<dropdown-input (valueChange)="setLogo($event)" *ngIf="showProductDropdown" [formGroup]="form" [name]="'productName'"
								[label]="'login.selectApplication' | translate" [data]="products"></dropdown-input>
							<text-input [formGroup]="form" [name]="'email'"
								[label]="'login.emailAddress' | translate"></text-input>
							<password-input [formGroup]="form" [name]="'password'"
								[label]="'login.password' | translate"></password-input>
							<p class="small mb-5 pb-lg-2"><a class="text-dark-50" routerLink="forgot-password">Forgot password?</a></p>
	
							<submit-button [disabled]="form.invalid" [isLoading]="isSaving" [class]="'btn btn-primary my-2 w-100'"
								(onClick)="login()">
								{{ 'login.login' | translate }}
							</submit-button>
	
							<img [src]="logoSrc" alt="" class="img-fluid w-25 d-flex mx-auto mt-5">
						</div>
						<div appFadeIn *ngIf="availableTenants">
							<dropdown-input [formGroup]="tenantForm" [data]="availableTenants" [name]="'tenantId'"
								[label]="'login.selectTenant' | translate">
							</dropdown-input>
							<div>
								<submit-button [disabled]="form.invalid" [isLoading]="isSaving" [class]="'btn btn-primary my-2 w-100'"
									(onClick)="selectTenant()">
									{{ 'login.continue' | translate }}
								</submit-button>
								<submit-button [disabled]="form.invalid" [isLoading]="isSaving" [class]="'btn btn-link my-2 w-100'"
									(onClick)="cancelTenantSelect()">
									Cancel
							</submit-button>
							</div>
						</div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="image-wrapper">
                    <img src="../../assets/img/image.jpg" class="img">
                    <div class="wave-wrap">
                        <svg class="wave" viewBox="0 0 783 1536" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="wave" d="m236.71,1356.18c-38.2,159.44 -172.21,172.36 -235.71,178.82l0,-1534l769.54,0c-18.68,62.12
                            -11.31,176.2 -146.38,230.53c-135.07,54.33 -203.79,174.19 -130.28,417.18c73.51,242.99
                            -44.8,259.69 -166.32,393.57c-121.52,133.88 -52.65,154.46 -90.85,313.9z" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<!-- <div class="container py-5 h-100">
	<div class="row d-flex justify-content-center align-items-center h-100">
		<div class="col-12 col-md-8 col-lg-6 col-xl-5">
			<div *ngIf="!isLoading" appFadeIn class="card bg-light text-dark" style="border-radius: 1rem;">
				<div class="card-body p-5">

					<div class="py-1" *ngIf="!availableTenants">
						<div class="text-center">
							<h2 class="fw-bold mb-2 text-uppercase">Login</h2>
							<p class="text-dark-50 mb-2">Please enter your email and password!</p>
							<span *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</span>
						</div>

						<dropdown-input (valueChange)="setLogo($event)" *ngIf="showProductDropdown" [formGroup]="form" [name]="'productName'"
							[label]="'login.selectApplication' | translate" [data]="products"></dropdown-input>
						<text-input [formGroup]="form" [name]="'email'"
							[label]="'login.emailAddress' | translate"></text-input>
						<password-input [formGroup]="form" [name]="'password'"
							[label]="'login.password' | translate"></password-input>
						<p class="small mb-5 pb-lg-2"><a class="text-dark-50" routerLink="forgot-password">Forgot password?</a></p>

						<submit-button [disabled]="form.invalid" [isLoading]="isSaving" [class]="'btn btn-primary my-2 w-100'"
							(onClick)="login()">
							{{ 'login.login' | translate }}
						</submit-button>

						<img [src]="logoSrc" alt="" class="img-fluid w-25 d-flex mx-auto mt-5">
					</div>
					<div appFadeIn *ngIf="availableTenants">
						<dropdown-input [formGroup]="tenantForm" [data]="availableTenants" [name]="'tenantId'"
							[label]="'login.selectTenant' | translate">
						</dropdown-input>
						<div>
							<submit-button [disabled]="form.invalid" [isLoading]="isSaving" [class]="'btn btn-primary my-2 w-100'"
								(onClick)="selectTenant()">
								{{ 'login.continue' | translate }}
							</submit-button>
							<submit-button [disabled]="form.invalid" [isLoading]="isSaving" [class]="'btn btn-link my-2 w-100'"
								(onClick)="cancelTenantSelect()">
								Cancel
						</submit-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div> -->