import { Pipe, PipeTransform } from '@angular/core';
import { Translation } from './translate.service';

@Pipe({
	name: 'translate',
	standalone: true
})
export class TranslatePipe implements PipeTransform {

	public transform(value: string): string {
		return Translation.getResource(value);
	}

}
