<p-dialog [header]="title" 
			[modal]="true" 
			[visible]="opened"
			[style]="{ width: width + 'px', maxWidth: '90%', maxHeight: '90vh' }" 
			[draggable]="false" 
			[resizable]="false">
	<ng-template pTemplate="closeicon">
		<i (click)="closeClick()" class="fas fa-times close-icon"></i>
	</ng-template>
	<p class="app-dialog-message" [innerHTML]="message" *ngIf="message != null"></p>
	<ng-container *ngIf="messageTemplate != null">
        <ng-container class="app-dialog-message-custom-content"
            *ngTemplateOutlet="messageTemplate; context: templateContext">
        </ng-container>
    </ng-container>
	<ng-template pTemplate="footer">
		<button *ngIf="yesVisible" [disabled]="okDisabled" (click)="yesClick()" class="btn btn-primary">
            {{okTitle}}
        </button>

        <button (click)="noClick()" *ngIf="cancelVisible" class="btn btn-light">
            {{noTitle}}
        </button>	
	</ng-template>
</p-dialog>
