import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'camelCase',
	standalone: true
})
export class CamelCasePipe implements PipeTransform {

	public transform(value: string): string {
		return value.charAt(0).toLowerCase() + value.slice(1);
	}

}
