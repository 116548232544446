import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Observable, Subscription, map, take } from 'rxjs';
import { DialogService } from '../../../core/services/dialog.service';
import { Notification } from '../../../core/services/notification.service';
import { FadeInDirective } from '../../../shared/directives/fade-in.directive';
import { AuthService, IdName, IsEmailInUseCommand, ProductRole, RegisterUserCommand, TenantDto, TenantService, UserDto } from '../../../shared/generated';
import { ProductService } from '../../../shared/generated/api/product.service';
import { FormGroupHelper } from '../../../shared/helpers/form-group.helper';
import { CheckboxInputComponent } from '../../../shared/inputs/checkbox-input/checkbox-input.component';
import { DropdownInputComponent } from '../../../shared/inputs/dropdown-input/dropdown-input.component';
import { MultiselectInputComponent } from '../../../shared/inputs/multiselect-input/multiselect-input.component';
import { PasswordInputComponent } from '../../../shared/inputs/password-input/password-input.component';
import { SubmitButtonComponent } from '../../../shared/inputs/submit-button/submit-button.component';
import { TextInputComponent } from '../../../shared/inputs/text-input/text-input.component';
import { TranslatePipe } from '../../../shared/translations/translate.pipe';
import { Translation } from '../../../shared/translations/translate.service';
import { ControlsOf } from '../../../shared/types/control-of.type';
import { PasswordMatch } from '../../../shared/validators/password-match.validator';

@Component({
	selector: 'app-user-edit',
	standalone: true,
	imports: [
		CommonModule,
		DropdownInputComponent,
		TextInputComponent,
		ReactiveFormsModule,
		PasswordInputComponent,
		TranslatePipe,
		SubmitButtonComponent,
		RouterModule,
		CheckboxInputComponent,
		FadeInDirective,
		MultiselectInputComponent
	],
	templateUrl: './user-edit.component.html',
	styleUrl: './user-edit.component.scss'
})
export class UserEditComponent implements OnInit, OnDestroy {
	public static userEdit: UserEditComponent;
	public userId: number;
	public title = 'admin.userNew';
	public form: FormGroup<ControlsOf<UserDto | RegisterUserCommand>>;
	private subscriptions: Subscription[] = [];
	public isLoading: boolean;
	public tenants$: Observable<TenantDto[]>;
	public roleDropdown$: Observable<IdName[]>;
	public products: IdName[];
	public isSuperAdmin: boolean;
	private initialData: string;

	constructor(private route: ActivatedRoute,
		private authService: AuthService,
		private tenantService: TenantService,
		private router: Router,
		private productService: ProductService) {
		UserEditComponent.userEdit = this;
	}

	public ngOnInit() {
		this.userId = +this.route.snapshot.paramMap.get('id');
		this.title = this.userId ? 'admin.userEdit' : 'admin.userNew';
		this.tenants$ = this.tenantService.getAllTenants();
		this.roleDropdown$ = this.authService.getAllRoles().pipe(map(r => r.filter(r => r.name !== 'SuperAdmin')));

		this.initForm();

		if (this.userId) {
			this.getUser();
			this.form.get('password').setValidators(null);
			this.form.get('password').updateValueAndValidity({ emitEvent: false });
			this.form.get('confirmPassword').setValidators(null);
			this.form.get('confirmPassword').updateValueAndValidity({ emitEvent: false });
		}
	}

	private initForm() {
		this.form = new FormGroup<ControlsOf<UserDto | RegisterUserCommand>>({
			id: new FormControl(0),
			firstName: new FormControl(null, Validators.required),
			lastName: new FormControl(null, Validators.required),
			email: new FormControl(null, Validators.required),
			tenantId: new FormControl(null, Validators.required),
			emailConfirmed: new FormControl(true),
			password: new FormControl(null, Validators.required),
			confirmPassword: new FormControl(null, Validators.required),
			active: new FormControl(true),
			products: new FormArray([]) as any
		}, [PasswordMatch.validate('password', 'confirmPassword')]);
		this.setInitialValue();
	}

	private getUser() {
		this.subscriptions.push(
			this.authService.getUser(this.userId).subscribe(user => {
				this.onTenantSelect(user.tenantId);
				this.form.patchValue({ ...user, emailConfirmed: true });
				user.products.forEach(p => this.addUserProduct(p));
				this.setInitialValue();
			})
		);
	}

	public addUserProduct(userProduct?: ProductRole) {
		this.userProducts.push(new FormGroup({
			productId: new FormControl(userProduct?.productId ?? null, Validators.required),
			role: new FormControl(userProduct?.role ?? null, Validators.required)
		}));
	}

	public removeUserProduct(index: number) {
		this.userProducts.removeAt(index);
	}

	private setInitialValue() {
		this.initialData = JSON.stringify(this.form.value);
	}

	public onTenantSelect(tenantId: number) {
		this.productService.getAvailableTenantProducts(tenantId)
			.pipe(
				map(products => products.map(p => ({ id: p.id, name: p.name }))),
				take(1)
			)
			.subscribe(products => {
				this.products = products;
			});
	}

	public onSubmit() {
		if (this.form.invalid) {
			FormGroupHelper.markInvalidFields(this.form);
			return;
		}
		const request: IsEmailInUseCommand = {
			email: this.form.controls['email'].value
		};

		this.subscriptions.push(
			this.authService.isUserEmailInUse(request).subscribe({
				next: (res) => {
					if (res.email.length > 0) {
						DialogService.okDialog(
							'Warning',
							'Email is already in use.'
						);
						return;
					} else {
						if (this.userProducts.length === 0) {
							DialogService.okDialog(
								'Warning',
								'Please add at least one product.'
							);
							return;
						}
				
						DialogService.areYouSureDialog(
							() => {
								this.isLoading = true;
								if (this.userId) {
									this.updateUser();
									return;
								}
								this.createUser();
							}
						);
					}
				},
				error: () => {
					
				}
			})
		);

	}

	private updateUser() {
		const user = this.form.value as UserDto;

		this.subscriptions.push(
			this.authService.updateUser(this.userId, user).subscribe({
				next: () => {
					Notification.showSuccess(Translation.getResource('admin.userUpdateSuccess'));
					this.router.navigate(['../'], { relativeTo: this.route });
				},
				error: () => this.isLoading = false
			})
		);
	}

	private createUser() {
		const user = this.form.value as RegisterUserCommand;
		const callback = this.authService.registerUser(user);

		this.subscriptions.push(
			callback.subscribe({
				next: (res) => {
					if (res.isAuthSuccessful) {
						Notification.showSuccess(Translation.getResource('admin.userCreateSuccess'));
						this.router.navigate(['../'], { relativeTo: this.route });
					} else {
						Notification.showError(res.errorMessage['RegisteredUserExist']);
						this.router.navigate(['../'], { relativeTo: this.route });
					}
				},
				error: () => this.isLoading = false
			})
		);
	}

	public cancel() {
		this.router.navigate(['../'], { relativeTo: this.route });
	}

	public canDeactivate(): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			const currentFormValue = JSON.stringify(this.form.value);
			if (this.initialData !== currentFormValue && !this.isLoading) {
				DialogService.unsavedChangesDialog(
					() => {
						resolve(true);
					},
					() => {
						resolve(false);
					}
				);
			} else {
				resolve(true);
			}
		});
	}

	public productDisabled(product: IdName) {
		return UserEditComponent.userEdit.userProducts.controls.some(p => p.value.productId === product.id);
	}

	public get userProducts() {
		return this.form.get('products') as FormArray;
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}
}
