import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'columnFilterOperator',
	standalone: true
})
export class ColumnFilterOperatorPipe implements PipeTransform {

	public transform(value: 'text' | 'numeric' | 'date' | 'boolean' | 'dropdown'): string {
		switch (value) {
			case 'text':
				return 'contains';
			case 'numeric':			
			case 'date':
			case 'boolean':
			case 'dropdown':
				return 'equals';

		}
	}

}
