import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TextInputComponent } from '../shared/inputs/text-input/text-input.component';
import { PasswordInputComponent } from '../shared/inputs/password-input/password-input.component';
import { TranslatePipe } from '../shared/translations/translate.pipe';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs';
import { LoginCommand, AuthService, IdName, SwitchTenantCommand } from '../shared/generated';
import { AppAuthService } from '../core/services/auth.service';
import { FadeInDirective } from '../shared/directives/fade-in.directive';
import { SubmitButtonComponent } from '../shared/inputs/submit-button/submit-button.component';
import { DropdownInputComponent } from '../shared/inputs/dropdown-input/dropdown-input.component';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProductName } from '../shared/helpers/product-name';
import { environment } from '../../environments/environment';
import { CamelCasePipe } from '../shared/pipes/camel-case.pipe';

@Component({
	selector: 'app-login',
	standalone: true,
	imports: [
		CommonModule,
		TextInputComponent,
		PasswordInputComponent,
		TranslatePipe,
		SubmitButtonComponent,
		FadeInDirective,
		DropdownInputComponent,
		CamelCasePipe,
		RouterModule
	],
	providers: [CamelCasePipe],
	templateUrl: './login.component.html',
	styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
	public form: FormGroup<{ email: FormControl<string>; password: FormControl<string>; productName: FormControl<ProductName> }>;
	public isLoading: boolean;
	public token: string;
	public availableTenants: IdName[];
	public tenantForm: FormGroup<{ tenantId: FormControl<any>; }>;
	public errorMessage: string;
	public products = Object.values(ProductName).map(v => {
		return { id: v, name: v };
	});
	public showProductDropdown: boolean;
	private isMobileApp: boolean;
	public isSaving: boolean;
	public logoSrc: string = 'assets/img/resplit-logo.png';
	public showLogoutMsg: boolean = true;

	constructor(
		private authService: AuthService, 
		private route: ActivatedRoute, 
		private camelCasePipe: CamelCasePipe, 
		private router: Router
	) {
	}

	public ngOnInit(): void {
		this.isLoading = true;
		this.initForm();
		const productName = this.route.snapshot.queryParamMap.get('productName') as ProductName;
		const shouldLogout = this.route.snapshot.queryParamMap.get('logout') as ProductName;
		this.isMobileApp = Boolean(this.route.snapshot.queryParamMap.get('isMobile'));
		const isProductNameValid = Object.values(ProductName).includes(productName);
		this.form.controls.productName.setValue(productName);
		this.router.navigate([], { queryParams: {} });
		if (shouldLogout) {
			AppAuthService.logout();
		}

		if (AppAuthService.isAuthenticated() && isProductNameValid) {
			const tenants = AppAuthService.getAvailableTenants();
			window.location.href = tenants?.length > 0 ?  `${this.getRedirectUrl()}&tenants=${JSON.stringify(tenants)}` : this.getRedirectUrl();
			return;
		}

		if (!isProductNameValid) {
			this.showProductDropdown = true;
		}

		if (isProductNameValid) {
			this.setLogo(productName);
		}
		this.isLoading = false;
	}

	private initForm() {
		this.form = new FormGroup({
			email: new FormControl(environment.production ? null : 'info@techresources.hr', Validators.required),
			password: new FormControl(environment.production ? null : 'Dalmacija2@24!', Validators.required),
			productName: new FormControl(null, Validators.required)
		});
	}

	public login() {
		if (this.form.valid) {
			this.isSaving = true;

			const value = this.form.getRawValue() as LoginCommand;
			this.authService.login(value).pipe(take(1)).subscribe({
				next: res => {
					if (res.isAuthSuccessful && res.tenants?.length) {
						this.token = res.token;
						AppAuthService.setAuthToken(res.token);
						this.availableTenants = res.tenants;
						this.tenantForm = new FormGroup({ tenantId: new FormControl(null, Validators.required) });
						this.isSaving = false;
						return;
					}
	
					if (res.isAuthSuccessful) {
						AppAuthService.setAuthToken(res.token);
						window.location.href = this.getRedirectUrl();
						return;
					}
	
					this.errorMessage = res.errorMessage['Login'];
	
					this.isSaving = false;
				},
				error: () => {
					this.isSaving = false;
				}
			});
		}
	}

	public selectTenant() {
		if (this.tenantForm.valid) {
			const tenantFormValue = this.tenantForm.getRawValue() as SwitchTenantCommand;
			tenantFormValue.email = this.form.get('email').value;
			tenantFormValue.productName = this.productName;
			this.authService.defaultHeaders = new HttpHeaders({ 'Authorization': `Bearer ${this.token}` });
			this.authService.switchTenant(tenantFormValue.email, tenantFormValue).pipe(take(1)).subscribe(res => {
				AppAuthService.setAuthToken(res.token);
				AppAuthService.setAvailableTenants(res.tenants);
				window.location.href = `${this.getRedirectUrl()}&tenants=${JSON.stringify(res.tenants)}`;
			});
		}
	}

	public cancelTenantSelect() {
		AppAuthService.logout();
		this.availableTenants = null;
	}

	public setLogo(productName: ProductName) {
		switch (productName) {
			case ProductName.ReRoute:
				this.logoSrc = 'assets/img/reroute-logo.svg';
				break;
			default:
				this.logoSrc = 'assets/img/resplit-logo.png';
				break;
		}
	}

	private getRedirectUrl(): string {
		return `${this.getProductUrl()}/auth?token=${AppAuthService.getAuthToken()}`;
	}

	private getProductUrl(): string {
		const productName = this.camelCasePipe.transform(this.productName);
		const propertyName = this.isMobileApp ? `${productName}MobileUrl` : `${productName}Url`;
		return environment[propertyName];
	}

	private get productName(): ProductName {
		return this.form.get('productName').value;
	}
}
