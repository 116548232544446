import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FadeInDirective } from '../../shared/directives/fade-in.directive';
import { SidebarModule } from 'primeng/sidebar';
import { AppAuthService } from '../../core/services/auth.service';

@Component({
	selector: 'app-layout',
	standalone: true,
	imports: [RouterModule, CommonModule, FadeInDirective, SidebarModule],
	templateUrl: './layout.component.html',
	styleUrl: './layout.component.scss'
})
export class LayoutComponent {
	public sidebarVisible: any;

	public toggleNav() {		
		this.sidebarVisible = !this.sidebarVisible;
	}

	public logout() {
		AppAuthService.logout();
	}
}
