import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FadeInDirective } from '../../shared/directives/fade-in.directive';
import { RouterModule } from '@angular/router';
import { ChartModule } from 'primeng/chart';

@Component({
	selector: 'app-home',
	standalone: true,
	imports: [CommonModule, FadeInDirective, RouterModule, ChartModule],
	templateUrl: './home.component.html',
	styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
	public data: any;

	public options: any;

	public ngOnInit() {
		const documentStyle = getComputedStyle(document.documentElement);
		const textColor = documentStyle.getPropertyValue('--text-color');
		const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
		const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        
		this.data = {
			labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			datasets: [
				{
					label: 'ReRoute',										
					data: [65, 59, 80, 81, 56, 55, 40, 20, 31, 51, 80, 55]
				},
				{
					label: 'CRM',										
					data: [28, 48, 40, 19, 86, 27, 90, 30, 21, 31, 50, 95]
				},
				{
					label: 'Timekeeping',										
					data: [18, 38, 10, 49, 66, 17, 50, 10, 21, 41, 70, 45]
				}
			]
		};

		this.options = {
			maintainAspectRatio: false,
			aspectRatio: 0.8,
			plugins: {
				legend: {
					labels: {
						color: textColor
					}
				}
			},
			scales: {
				x: {
					ticks: {
						color: textColorSecondary,
						font: {
							weight: 500
						}
					},
					grid: {
						color: surfaceBorder,
						drawBorder: false
					}
				},
				y: {
					ticks: {
						color: textColorSecondary
					},
					grid: {
						color: surfaceBorder,
						drawBorder: false
					}
				}

			}
		};
	}
}
