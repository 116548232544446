import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BASE_PATH } from './shared/generated';
import { environment } from '../environments/environment';
import { JwtModule } from '@auth0/angular-jwt';
import { AppAuthService } from './core/services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageDialogComponent } from './shared/components/message-dialog/message-dialog.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';

function tokenGetter() {
	return AppAuthService.getAuthToken();
}

@NgModule({
	declarations: [
		AppComponent,
		MessageDialogComponent
	],
	imports: [
		BrowserModule,
		ToastModule,
		HttpClientModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		DialogModule,
		JwtModule.forRoot({
			config: {
				tokenGetter,
				allowedDomains: [environment.allowedDomain]
			}
		})
	],
	providers: [
		MessageService,
		{
			provide: BASE_PATH,
			useValue: environment.apiUrl
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
