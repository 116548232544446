/**
 * ReRoute API
 * An ASP.NET Core Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
	HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
}       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AdminLoginCommand } from '../model/adminLoginCommand';
// @ts-ignore
import { AssignProductCommand } from '../model/assignProductCommand';
// @ts-ignore
import { ChangePasswordCommand } from '../model/changePasswordCommand';
// @ts-ignore
import { ChangePasswordResponseDto } from '../model/changePasswordResponseDto';
// @ts-ignore
import { DeleteUserCommand } from '../model/deleteUserCommand';
// @ts-ignore
import { EntityUserDto } from '../model/entityUserDto';
// @ts-ignore
import { ForgotPasswordCommand } from '../model/forgotPasswordCommand';
// @ts-ignore
import { IdName } from '../model/idName';
// @ts-ignore
import { IsEmailInUseCommand } from '../model/isEmailInUseCommand';
// @ts-ignore
import { LoginCommand } from '../model/loginCommand';
// @ts-ignore
import { LoginResponseDto } from '../model/loginResponseDto';
// @ts-ignore
import { ReactivateUserCommand } from '../model/reactivateUserCommand';
// @ts-ignore
import { RegisterUserCommand } from '../model/registerUserCommand';
// @ts-ignore
import { ResetPasswordCommand } from '../model/resetPasswordCommand';
// @ts-ignore
import { SwitchTenantCommand } from '../model/switchTenantCommand';
// @ts-ignore
import { ToggleUserProductsCommand } from '../model/toggleUserProductsCommand';
// @ts-ignore
import { UpdateUserCommand } from '../model/updateUserCommand';
// @ts-ignore
import { UpdateUserEmailCommand } from '../model/updateUserEmailCommand';
// @ts-ignore
import { UpdateUserProductRoleCommand } from '../model/updateUserProductRoleCommand';
// @ts-ignore
import { UserDto } from '../model/userDto';
// @ts-ignore
import { UserDtoGridData } from '../model/userDtoGridData';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
	providedIn: 'root'
})
export class AuthService {

	protected basePath = 'http://localhost';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();
	public encoder: HttpParameterCodec;

	constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
		if (configuration) {
			this.configuration = configuration;
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (Array.isArray(basePath) && basePath.length > 0) {
				basePath = basePath[0];
			}

			if (typeof basePath !== 'string') {
				basePath = this.basePath;
			}
			this.configuration.basePath = basePath;
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
	}


	// @ts-ignore
	private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
		if (typeof value === 'object' && value instanceof Date === false) {
			httpParams = this.addToHttpParamsRecursive(httpParams, value);
		} else {
			httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
		}
		return httpParams;
	}

	private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
		if (value == null) {
			return httpParams;
		}

		if (typeof value === 'object') {
			if (Array.isArray(value)) {
				(value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
			} else if (value instanceof Date) {
				if (key != null) {
					httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
				} else {
					throw Error('key may not be null if value is Date');
				}
			} else {
				Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
					httpParams, value[k], key != null ? `${key}.${k}` : k));
			}
		} else if (key != null) {
			httpParams = httpParams.append(key, value);
		} else {
			throw Error('key may not be null if value is not object or array');
		}
		return httpParams;
	}

	/**
     * @param adminLoginCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public adminLogin(adminLoginCommand?: AdminLoginCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<LoginResponseDto>;
	public adminLogin(adminLoginCommand?: AdminLoginCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<LoginResponseDto>>;
	public adminLogin(adminLoginCommand?: AdminLoginCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<LoginResponseDto>>;
	public adminLogin(adminLoginCommand?: AdminLoginCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/adminLogin';
		return this.httpClient.request<LoginResponseDto>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: adminLoginCommand,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param assignProductCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public assignUserToProduct(assignProductCommand?: AssignProductCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
	public assignUserToProduct(assignProductCommand?: AssignProductCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
	public assignUserToProduct(assignProductCommand?: AssignProductCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
	public assignUserToProduct(assignProductCommand?: AssignProductCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/assignUserToProduct';
		return this.httpClient.request<number>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: assignProductCommand,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param changePasswordCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public changePassword(changePasswordCommand?: ChangePasswordCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ChangePasswordResponseDto>;
	public changePassword(changePasswordCommand?: ChangePasswordCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ChangePasswordResponseDto>>;
	public changePassword(changePasswordCommand?: ChangePasswordCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ChangePasswordResponseDto>>;
	public changePassword(changePasswordCommand?: ChangePasswordCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/changePassword';
		return this.httpClient.request<ChangePasswordResponseDto>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: changePasswordCommand,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param deleteUserCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public deleteUser(deleteUserCommand?: DeleteUserCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
	public deleteUser(deleteUserCommand?: DeleteUserCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
	public deleteUser(deleteUserCommand?: DeleteUserCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
	public deleteUser(deleteUserCommand?: DeleteUserCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/deleteUser';
		return this.httpClient.request<number>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: deleteUserCommand,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param forgotPasswordCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public forgotPassword(forgotPasswordCommand?: ForgotPasswordCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
	public forgotPassword(forgotPasswordCommand?: ForgotPasswordCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
	public forgotPassword(forgotPasswordCommand?: ForgotPasswordCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
	public forgotPassword(forgotPasswordCommand?: ForgotPasswordCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/forgotPassword';
		return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: forgotPasswordCommand,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public getAllRoles(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<IdName>>;
	public getAllRoles(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<IdName>>>;
	public getAllRoles(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<IdName>>>;
	public getAllRoles(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/getAllRoles';
		return this.httpClient.request<Array<IdName>>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param productId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public getAllUsersForGrid(productId?: number, body?: object, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<UserDtoGridData>;
	public getAllUsersForGrid(productId?: number, body?: object, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<UserDtoGridData>>;
	public getAllUsersForGrid(productId?: number, body?: object, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<UserDtoGridData>>;
	public getAllUsersForGrid(productId?: number, body?: object, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
		if (productId !== undefined && productId !== null) {
			localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productId, 'productId');
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/getUsersForGrid';
		return this.httpClient.request<UserDtoGridData>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: body,
				params: localVarQueryParameters,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public getUser(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<UserDto>;
	public getUser(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<UserDto>>;
	public getUser(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<UserDto>>;
	public getUser(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
		if (id === null || id === undefined) {
			throw new Error('Required parameter id was null or undefined when calling getUser.');
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = `/api/Auth/getUser/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int32' })}`;
		return this.httpClient.request<UserDto>('get', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public getUserByEmail(email: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<UserDto>;
	public getUserByEmail(email: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<UserDto>>;
	public getUserByEmail(email: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<UserDto>>;
	public getUserByEmail(email: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
		if (email === null || email === undefined) {
			throw new Error('Required parameter email was null or undefined when calling getUserByEmail.');
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = `/api/Auth/getUserByEmail/${this.configuration.encodeParam({ name: 'email', value: email, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
		return this.httpClient.request<UserDto>('get', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param role 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public getUsersInRole(role?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<EntityUserDto>>;
	public getUsersInRole(role?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<EntityUserDto>>>;
	public getUsersInRole(role?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<EntityUserDto>>>;
	public getUsersInRole(role?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
		if (role !== undefined && role !== null) {
			localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>role, 'role');
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/getUsersInRole';
		return this.httpClient.request<Array<EntityUserDto>>('get', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				params: localVarQueryParameters,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param role 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public getUsersInRoleForGrid(role?: string, body?: object, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<UserDtoGridData>;
	public getUsersInRoleForGrid(role?: string, body?: object, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<UserDtoGridData>>;
	public getUsersInRoleForGrid(role?: string, body?: object, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<UserDtoGridData>>;
	public getUsersInRoleForGrid(role?: string, body?: object, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
		if (role !== undefined && role !== null) {
			localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>role, 'role');
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/getUsersInRoleForGrid';
		return this.httpClient.request<UserDtoGridData>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: body,
				params: localVarQueryParameters,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param isEmailInUseCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public isUserEmailInUse(isEmailInUseCommand?: IsEmailInUseCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<UserDto>;
	public isUserEmailInUse(isEmailInUseCommand?: IsEmailInUseCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<UserDto>>;
	public isUserEmailInUse(isEmailInUseCommand?: IsEmailInUseCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<UserDto>>;
	public isUserEmailInUse(isEmailInUseCommand?: IsEmailInUseCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/isUserEmailInUse';
		return this.httpClient.request<UserDto>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: isEmailInUseCommand,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param loginCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public login(loginCommand?: LoginCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<LoginResponseDto>;
	public login(loginCommand?: LoginCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<LoginResponseDto>>;
	public login(loginCommand?: LoginCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<LoginResponseDto>>;
	public login(loginCommand?: LoginCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/login';
		return this.httpClient.request<LoginResponseDto>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: loginCommand,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param reactivateUserCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public reactivateUser(reactivateUserCommand?: ReactivateUserCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
	public reactivateUser(reactivateUserCommand?: ReactivateUserCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
	public reactivateUser(reactivateUserCommand?: ReactivateUserCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
	public reactivateUser(reactivateUserCommand?: ReactivateUserCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/reactivateUser';
		return this.httpClient.request<number>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: reactivateUserCommand,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param registerUserCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public registerUser(registerUserCommand?: RegisterUserCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<LoginResponseDto>;
	public registerUser(registerUserCommand?: RegisterUserCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<LoginResponseDto>>;
	public registerUser(registerUserCommand?: RegisterUserCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<LoginResponseDto>>;
	public registerUser(registerUserCommand?: RegisterUserCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/registerUser';
		return this.httpClient.request<LoginResponseDto>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: registerUserCommand,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param resetPasswordCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public resetPassword(resetPasswordCommand?: ResetPasswordCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
	public resetPassword(resetPasswordCommand?: ResetPasswordCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
	public resetPassword(resetPasswordCommand?: ResetPasswordCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
	public resetPassword(resetPasswordCommand?: ResetPasswordCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/resetPassword';
		return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: resetPasswordCommand,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param userEmail 
     * @param switchTenantCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public switchTenant(userEmail?: string, switchTenantCommand?: SwitchTenantCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<LoginResponseDto>;
	public switchTenant(userEmail?: string, switchTenantCommand?: SwitchTenantCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<LoginResponseDto>>;
	public switchTenant(userEmail?: string, switchTenantCommand?: SwitchTenantCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<LoginResponseDto>>;
	public switchTenant(userEmail?: string, switchTenantCommand?: SwitchTenantCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
		if (userEmail !== undefined && userEmail !== null) {
			localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userEmail, 'userEmail');
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/switchTenant';
		return this.httpClient.request<LoginResponseDto>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: switchTenantCommand,
				params: localVarQueryParameters,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param toggleUserProductsCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public toggleUserProducts(toggleUserProductsCommand?: ToggleUserProductsCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
	public toggleUserProducts(toggleUserProductsCommand?: ToggleUserProductsCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
	public toggleUserProducts(toggleUserProductsCommand?: ToggleUserProductsCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
	public toggleUserProducts(toggleUserProductsCommand?: ToggleUserProductsCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/toggleUserProducts';
		return this.httpClient.request<number>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: toggleUserProductsCommand,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param id 
     * @param updateUserCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public updateUser(id?: number, updateUserCommand?: UpdateUserCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
	public updateUser(id?: number, updateUserCommand?: UpdateUserCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
	public updateUser(id?: number, updateUserCommand?: UpdateUserCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
	public updateUser(id?: number, updateUserCommand?: UpdateUserCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
		if (id !== undefined && id !== null) {
			localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/updateUser';
		return this.httpClient.request<number>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: updateUserCommand,
				params: localVarQueryParameters,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param updateUserEmailCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public updateUserEmail(updateUserEmailCommand?: UpdateUserEmailCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<boolean>;
	public updateUserEmail(updateUserEmailCommand?: UpdateUserEmailCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<boolean>>;
	public updateUserEmail(updateUserEmailCommand?: UpdateUserEmailCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<boolean>>;
	public updateUserEmail(updateUserEmailCommand?: UpdateUserEmailCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/updateUserEmail';
		return this.httpClient.request<boolean>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: updateUserEmailCommand,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
     * @param updateUserProductRoleCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
	public updateUserRole(updateUserProductRoleCommand?: UpdateUserProductRoleCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
	public updateUserRole(updateUserProductRoleCommand?: UpdateUserProductRoleCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
	public updateUserRole(updateUserProductRoleCommand?: UpdateUserProductRoleCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
	public updateUserRole(updateUserProductRoleCommand?: UpdateUserProductRoleCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

		let localVarHeaders = this.defaultHeaders;

		let localVarCredential: string | undefined;
		// authentication (Bearer) required
		localVarCredential = this.configuration.lookupCredential('Bearer');
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
		}

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/plain',
				'application/json',
				'text/json'
			];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json',
			'text/json',
			'application/*+json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		const localVarPath = '/api/Auth/updateUserRole';
		return this.httpClient.request<number>('post', `${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				body: updateUserProductRoleCommand,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
