<div appFadeIn class="container py-5 h-100">
	<div class="row d-flex justify-content-center align-items-center h-100">
		<div class="col-12 col-md-8 col-lg-6 col-xl-5">
			<div class="card bg-light text-dark" style="border-radius: 1rem;">
				@if(!isPasswordReset) {
					<div class="card-body p-5">
						<button class="btn p-0 mb-4" routerLink="/">
							<i class="fas fa-arrow-left bg-primary rounded-circle p-2 text-light"></i>
						</button>
						<h5 class="fw-bold mb-4">Reset password</h5>					
						<span *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</span>
						<password-input [formGroup]="form" [name]="'password'" [showFeedback]="true"
							[label]="'login.password' | translate"></password-input>
						<submit-button [disabled]="form.invalid" [isLoading]="isLoading" [class]="'btn btn-primary my-2 w-100'"
							(onClick)="onSubmit()">
							{{ 'login.resetPassword' | translate }}
						</submit-button>
					</div>
				} @else {
					<div appFadeIn class="card-body p-5">
						<button class="btn p-0 mb-2">
							<i class="far fa-check-circle rounded-circle text-primary fs-2"></i>
						</button>
						<h5>Password reset</h5>
						<button routerLink="" class="btn text-primary fw-bold p-0 mt-1">Login now</button>							
					</div>
				}
			</div>
		</div>
	</div>
</div>