<p-table
    #dt
    [value]="gridData?.data"
    dataKey="id"
    [rowHover]="true"
    [rows]="10"
	[lazy]="true"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[5, 10, 25, 50]"
    [loading]="loading"
	[style.height.px]="dt._rows * 30 + 30"
	loadingIcon="spinner"
    [paginator]="true"
    currentPageReportTemplate="{{ 'general.showing' | translate }} {first} - {last} {{ 'general.of' | translate }} {totalRecords} {{ 'general.entries' | translate }}"    
	[totalRecords]="gridData?.total"
	(onSort)="onSort($event)"
	[customSort]="true"
	(onFilter)="onFilter($event)"
	(onPage)="onPage($event)"
>
    <ng-template pTemplate="header">
        <tr>
			<th [style.width]="'20px'" *ngIf="masterDetail"></th>
			<th *ngFor="let column of columns" [pSortableColumn]="column.sortable ? column.field : null">
				<div class="flex justify-content-between align-items-center">
					{{ column.content }}
					<p-sortIcon *ngIf="column.sortable" [field]="column.field"></p-sortIcon>
				</div>
			</th>
			<th *ngIf="commandColumn"></th>
        </tr>
		<tr *ngIf="filterable">
			<th *ngIf="masterDetail"></th>
			@for(column of columns; track column.field) {
				<th>
					<p-columnFilter *ngIf="column.filter && column.filter !== 'dropdown'"
									[type]="column.filter" 
									[field]="column.field" 
									[matchMode]="column.filter | columnFilterOperator" 
									[showMenu]="false">
					</p-columnFilter>
					<p-columnFilter *ngIf="column.filter && column.filter === 'dropdown'" [field]="column.field" matchMode="equals" [showMenu]="false">
						<ng-template pTemplate="filter" let-value let-filter="filterCallback">
							<p-dropdown [ngModel]="value" 
										[options]="column.options" 
										appendTo="body" 
										(onChange)="filter($event.value)" 
										placeholder="Any" 
										[showClear]="true" 
										[optionValue]="column.optionValue || 'id'" 
										[optionLabel]="'name'"
										[filter]="true">
							</p-dropdown>
						</ng-template>
					</p-columnFilter>
				</th>
			}
			<th *ngIf="commandColumn"></th>
		</tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
        <tr class="p-selectable-row">
			<td (click)="toggleDetail(data.id)" *ngIf="masterDetail">
				<i class="fas" [ngClass]="visibleId === data.id ? 'fa-minus' : 'fa-plus'"></i>
			</td>
			<ng-container *ngTemplateOutlet="bodyTemplate; context: { $implicit: data }"></ng-container>
			<td *ngIf="commandColumn" style="width:1px;white-space:nowrap;">
				<ng-container *ngTemplateOutlet="commandColumnTemplate; context: { $implicit: data }"></ng-container>
			</td>
        </tr>
		<tr *ngIf="masterDetail && visibleId === data.id">
			<td [attr.colspan]="columns.length + 1">
				<ng-container *ngTemplateOutlet="masterDetailTemplate; context: { $implicit: data }"></ng-container>
			</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="20" class="text-center">No data found.</td>
        </tr>
    </ng-template>
</p-table>