import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
	selector: '[passwordMatch]',
	providers: [{ provide: NG_VALIDATORS, useExisting: PasswordMatch, multi: true }],
	standalone: true
})
export class PasswordMatch {
	public static validate(source: string, target: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const sourceCtrl = control.get(source);
			const targetCtrl = control.get(target);

			return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
				? { matchPassword: true }
				: null;
		};
	}
}