<div appFadeIn class="container py-5 h-100">
	<div class="row d-flex justify-content-center align-items-center h-100">
		<div class="col-12 col-md-8 col-lg-6 col-xl-5">
			<div class="card bg-light text-dark" style="border-radius: 1rem;">
				<div class="card-body p-5">
					<div class="text-center">
						<h3 class="fw-bold">Admin Login</h3>
						<p class="mb-4">Please enter your email and password to login</p>
						<span *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</span>
					</div>
					<text-input [formGroup]="form" [name]="'email'"
						[label]="'login.emailAddress' | translate"></text-input>
					<password-input [formGroup]="form" [name]="'password'"
						[label]="'login.password' | translate"></password-input>
					<submit-button [disabled]="form.invalid" [isLoading]="isLoading" [class]="'btn btn-primary my-2 w-100'"
						(onClick)="login()">
						{{ 'login.login' | translate }}
					</submit-button>
					<img src="assets/img/resplit-logo.png" alt="" class="img-fluid w-25 d-flex mx-auto mt-5">

				</div>
			</div>
		</div>
	</div>
</div>