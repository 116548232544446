import { GridRequest } from '../generated/model/gridRequest';

export function getDefaultGridState(take?: number): GridRequest {
	return {
		skip: 0,
		take: take ?? 10
	};
}

export const DefaultPageSettings = {
	info: false,
	buttonCount: 4,
	pageSizes: [5, 10, 50]
};
