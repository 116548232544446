import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GridColumn, GridComponent } from '../../shared/components/grid/grid.component';
import { FadeInDirective } from '../../shared/directives/fade-in.directive';
import { TranslatePipe } from '../../shared/translations/translate.pipe';
import { Observable, Subscription, tap } from 'rxjs';
import { getDefaultGridState, DefaultPageSettings } from '../../shared/helpers/grid.helper';
import { Translation } from '../../shared/translations/translate.service';
import { DialogService } from '../../core/services/dialog.service';
import { Notification } from '../../core/services/notification.service';
import { ProductDtoGridData } from '../../shared/generated/model/productDtoGridData';
import { ProductService } from '../../shared/generated/api/product.service';
import { ProductDto } from '../../shared/generated/model/productDto';

@Component({
	selector: 'app-products',
	standalone: true,
	imports: [CommonModule, GridComponent, RouterModule, TranslatePipe, FadeInDirective],
	templateUrl: './products.component.html',
	styleUrl: './products.component.scss'
})
export class ProductsComponent implements OnInit, OnDestroy {
	public isLoading: boolean;
	public state = getDefaultGridState();
	public pagerSettings = DefaultPageSettings;
	public products$: Observable<ProductDtoGridData>;
	public gridColumns: GridColumn[] = [
		{ field: 'name', content: 'Product Name', sortable: true },
		{ field: 'assignedTenants', content: 'Assigned Tenants', sortable: true }
	];
	private subscriptions: Subscription[] = [];

	constructor(private productService: ProductService) { }

	public ngOnInit() {
		this.getData();
	}

	public dataStateChange(state): void {
		this.state = state;
		this.getData();
	}

	private getData(): void {
		this.isLoading = true;
		this.products$ = this.productService.getAllProductsForGrid(this.state).pipe(tap({ next: () => this.isLoading = false, error: () => this.isLoading = false }));
	}

	public onDelete(product: ProductDto) {
		DialogService.areYouSureDialog(
			() => this.deleteProduct(product.id),
			null,
			'Are you sure you want to delete this product?',
			Translation.getResource('general.delete')
		);
	}

	private deleteProduct(id: number) {
		this.subscriptions.push(
			this.productService.deleteProduct({ id }).subscribe(() => {
				Notification.showSuccess(Translation.getResource('admin.tenantDeleteSuccess'));
				this.getData();
			})
		);
	}

	public viewTenants(tenants: string) {
		DialogService.okDialog('Assigned Tenants', tenants);
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}
}
