import { Router } from '@angular/router';
import { AppAuthService } from '../services/auth.service';
import { inject } from '@angular/core';

export const superAdminGuard = () => {
	const router: Router = inject(Router);
	if (AppAuthService.isSuperAdmin() && AppAuthService.isAuthenticated()) {
		return true;
	}

	return router.navigate(['/admin-login']);
};
