import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../shared/generated';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FadeInDirective } from '../shared/directives/fade-in.directive';
import { SubmitButtonComponent } from '../shared/inputs/submit-button/submit-button.component';
import { TranslatePipe } from '../shared/translations/translate.pipe';
import { PasswordInputComponent } from '../shared/inputs/password-input/password-input.component';

@Component({
	selector: 'app-reset-password',
	standalone: true,
	imports: [
		CommonModule, 
		PasswordInputComponent, 
		ReactiveFormsModule, 
		TranslatePipe, 
		SubmitButtonComponent, 
		FadeInDirective,
		RouterModule
	],
	templateUrl: './reset-password.component.html',
	styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {
	public form: FormGroup = new FormGroup({
		password: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required]),
		token: new FormControl('', [Validators.required])
	});
	public isLoading: boolean = false;
	public errorMessage: string = null;
	public isPasswordReset: boolean = false;

	constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {
		if (this.route.snapshot.queryParams['token']) {
			this.form.patchValue({ token: this.route.snapshot.queryParams['token'].replace(/\s+/g, '+') });
			this.form.patchValue({ email: this.route.snapshot.queryParams['email'] });
		} else {
			// this.router.navigate(['']);
		}
	}

	public onSubmit() {
		if (this.form.invalid) {
			return;
		}

		this.isLoading = true;
		this.authService.resetPassword(this.form.value).subscribe(
			{
				next: () => {
					this.isLoading = false;
					this.isPasswordReset = true;
				},
				error: (error) => {
					this.isLoading = false;
					this.errorMessage = error.error.message;
				} 
			}
		);
	}
}
