<div [formGroup]="formGroup" class="mb-2">
	<div class="d-flex flex-column gap-1">
		<label [class.app-label-required]="isRequired" [for]="label">{{ label }}</label>		
		<p-dropdown appendTo="body" [options]="data" [id]="label" [formControlName]="name" [readonly]="disabled" [placeholder]="label"
			[optionLabel]="textField" [optionValue]="valueField" [inputId]="label"
			optionDisabled="disabled"
			(onChange)="onValueChange($event.value)">
		</p-dropdown>
	</div>

	<span class="text-danger" *ngIf="formGroup.get(name).invalid && formGroup.get(name).dirty && label">
		{{ label }} is required
	</span>

	<span class="text-danger" *ngIf="formGroup.get(name).hasError('serverError')">
		{{ formGroup.get(name).errors?.['serverError'] }} <br>
	</span>
</div>