import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { HomeComponent } from './admin/home/home.component';
import { LayoutComponent } from './admin/layout/layout.component';
import { superAdminGuard } from './core/guards/superadmin.guard';
import { TenantsComponent } from './admin/tenants/tenants.component';
import { ProductsComponent } from './admin/products/products.component';
import { UsersComponent } from './admin/users/users.component';
import { ProductEditComponent } from './admin/products/product-edit/product-edit.component';
import { TenantEditComponent } from './admin/tenants/tenant-edit/tenant-edit.component';
import { UserEditComponent } from './admin/users/user-edit/user-edit.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
	{ path: '', component: LoginComponent },
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: 'reset-password', component: ResetPasswordComponent },
	{ path: 'admin-login', component: AdminLoginComponent },
	{
		path: 'admin', component: LayoutComponent, canActivate: [superAdminGuard], children: [
			{ path: 'home', component: HomeComponent },
			{ path: 'tenants', component: TenantsComponent },
			{ path: 'tenants/new', component: TenantEditComponent },
			{ path: 'tenants/:id', component: TenantEditComponent },
			{ path: 'products', component: ProductsComponent },
			{ path: 'products/new', component: ProductEditComponent },
			{ path: 'products/:id', component: ProductEditComponent },
			{ path: 'users', component: UsersComponent },
			{ path: 'users/new', component: UserEditComponent },
			{ path: 'users/:id', component: UserEditComponent }
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
