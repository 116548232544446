import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridColumn, GridComponent } from '../../shared/components/grid/grid.component';
import { Observable, Subscription, tap } from 'rxjs';
import { getDefaultGridState, DefaultPageSettings } from '../../shared/helpers/grid.helper';
import { Translation } from '../../shared/translations/translate.service';
import { DialogService } from '../../core/services/dialog.service';
import { TenantDto, TenantDtoGridData, TenantService } from '../../shared/generated';
import { Notification } from '../../core/services/notification.service';
import { RouterModule } from '@angular/router';
import { TranslatePipe } from '../../shared/translations/translate.pipe';
import { FadeInDirective } from '../../shared/directives/fade-in.directive';

@Component({
	selector: 'app-tenants',
	standalone: true,
	imports: [CommonModule, GridComponent, RouterModule, TranslatePipe, FadeInDirective],
	templateUrl: './tenants.component.html',
	styleUrl: './tenants.component.scss'
})
export class TenantsComponent implements OnInit, OnDestroy {
	public isLoading: boolean;
	public state = getDefaultGridState();
	public pagerSettings = DefaultPageSettings;
	public tenants$: Observable<TenantDtoGridData>;
	public gridColumns: GridColumn[] = [
		{ field: 'name', content: Translation.getResource('admin.tenantName'), sortable: true, filter: 'text' },
		{ field: 'groupAdmin', content: Translation.getResource('admin.tenantAdmin'), sortable: true, filter: 'text' }
	];
	private subscriptions: Subscription[] = [];

	constructor(private tenantService: TenantService) { }

	public ngOnInit() {
		this.getData();
	}

	public dataStateChange(state): void {
		this.state = state;
		this.getData();
	}

	private getData(): void {
		this.isLoading = true;
		this.tenants$ = this.tenantService.getAllTenantsForGrid(this.state).pipe(tap({ next: () => this.isLoading = false, error: () => this.isLoading = false }));
	}

	public onDelete(tenant: TenantDto) {
		DialogService.areYouSureDialog(
			() => this.deleteTenant(tenant.id),
			null,
			Translation.getResource('admin.tenantDeleteConfirm'),
			Translation.getResource('general.delete')
		);
	}

	private deleteTenant(id: number) {
		this.subscriptions.push(
			this.tenantService.deleteTenant({ id }).subscribe(() => {
				Notification.showSuccess(Translation.getResource('admin.tenantDeleteSuccess'));
				this.getData();
			})
		);
	}
	
	public ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

}
