import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TextInputComponent } from '../shared/inputs/text-input/text-input.component';
import { PasswordInputComponent } from '../shared/inputs/password-input/password-input.component';
import { SubmitButtonComponent } from '../shared/inputs/submit-button/submit-button.component';
import { TranslatePipe } from '../shared/translations/translate.pipe';
import { AdminLoginCommand, AuthService } from '../shared/generated';
import { take } from 'rxjs';
import { AppAuthService } from '../core/services/auth.service';
import { Router, RouterModule } from '@angular/router';
import { FadeInDirective } from '../shared/directives/fade-in.directive';
import { environment } from '../../environments/environment';

@Component({
	selector: 'app-admin-login',
	templateUrl: './admin-login.component.html',
	styleUrl: './admin-login.component.scss',
	standalone: true,
	imports: [
		CommonModule,
		TextInputComponent,
		PasswordInputComponent,
		SubmitButtonComponent,
		TranslatePipe,
		RouterModule,
		FadeInDirective
	]
})
export class AdminLoginComponent implements OnInit {
	public form: FormGroup<{ email: FormControl<string>; password: FormControl<string> }>;
	public isLoading: boolean;
	public errorMessage: string;

	constructor(private authService: AuthService, private router: Router) {

	}

	public ngOnInit(): void {
		this.initForm();		
	}

	private initForm() {
		this.form = new FormGroup({
			email: new FormControl(!environment.production ? 'info@techresources.hr' : null, Validators.required),
			password: new FormControl(!environment.production ? 'Dalmacija2@24!' : null, Validators.required)
		});
	}

	public login() {
		if (this.form.invalid) {
			return;
		}
		this.isLoading = true;

		const value = this.form.getRawValue() as AdminLoginCommand;
		this.authService.adminLogin(value).pipe(take(1)).subscribe({
			next: res => {
				if (res.isAuthSuccessful) {
					AppAuthService.setAuthToken(res.token);
					this.router.navigate(['/admin/home']);
					return;
				}
	
				this.errorMessage = res.errorMessage['Login'];
	
				this.isLoading = false;
			},
			error: () => {
				this.isLoading = false;
			}
		});
	}
}
