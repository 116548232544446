import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Translation } from '../../translations/translate.service';
import { InputText } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { PhoneNumberDirective } from '../../directives/phone-number.directive';
import { InputTextModule } from 'primeng/inputtext';

@Component({
	selector: 'text-input',
	templateUrl: './text-input.component.html',
	styleUrls: ['./text-input.component.scss'],
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, PhoneNumberDirective, InputTextModule]
})
export class TextInputComponent implements OnInit {
	@ViewChild('input') private input: InputText;
	@Input() public formGroup: FormGroup;
	@Input() public name: string;
	@Input() public label: string;
	@Input() public placeholder: string;
	@Input() public disabled = false;
	@Input() public isPhoneNumber = false;
	@Output() public valueChange = new EventEmitter();

	public showPasswordSuffix = false;
	public isRequired: boolean;

	constructor() {
	}

	public ngOnInit(): void {
		this.isRequired = this.formGroup.get(this.name).hasValidator(Validators.required);
		if (!this.label) {
			this.placeholder = `${this.placeholder ?? Translation.getResource('general.required')}`;
			this.placeholder = this.isRequired ? `${this.placeholder} *` : this.placeholder;
		}
	}

	public onValueChange(event: any) {
		this.valueChange.emit(event);
	}
}
