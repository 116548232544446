<div appFadeIn class="card">
	<div class="card-header">
		Tenants
		<button class="btn badge rounded-pill bg-app p-3 ms-2" routerLink="new"><i class="fas fa-plus"></i></button>
	</div>
	<div class="card-body">
		<app-grid 
			[data]="tenants$ | async"
			[columns]="gridColumns"
			[bodyTemplate]="body"
			(stateChange)="dataStateChange($event)"
			[loading]="isLoading"
			[filterable]="true"
			[commandColumn]="true"
			[commandColumnTemplate]="commandColumn">
			<ng-template #body let-data>
				<td>
					{{ data.name }}
				</td>
				<td>
					{{ data.groupAdmin }}
				</td>
			</ng-template>
			<ng-template #commandColumn let-data>
				<button class="btn badge rounded-pill bg-app p-3 mx-1" [routerLink]="data.id"><i class="fas fa-edit"></i></button>
				<button class="btn badge rounded-pill bg-danger p-3 mx-1" (click)="onDelete(data)"><i class="fas fa-trash"></i></button>
			</ng-template>
		</app-grid>
	</div>
</div>