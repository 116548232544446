import { Directive, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[appPhoneNumber]',
	standalone: true
})
export class PhoneNumberDirective {
	@Input() public applyPhoneValidation: boolean = false;

	@HostListener('input', ['$event'])
	public onInput(event: KeyboardEvent): void {
		if (!this.applyPhoneValidation) {
			return;
		}
		const input = event.target as HTMLInputElement;
		const inputValue = input.value;

		// Allow only numbers and plus sign
		const sanitizedValue = inputValue.replace(/[^0-9]/g, '');
		const newValue = inputValue.startsWith('+') ? '+' + sanitizedValue : sanitizedValue;

		// Update the input value
		if (inputValue !== newValue) {
			input.value = newValue;
			input.dispatchEvent(new Event('input'));
		}
	}
}