import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslatePipe } from '../../translations/translate.pipe';
import { CommonModule } from '@angular/common';
@Component({
	selector: 'submit-button',
	templateUrl: './submit-button.component.html',
	styleUrls: ['./submit-button.component.scss'],
	standalone: true,
	imports: [TranslatePipe, CommonModule]
})
export class SubmitButtonComponent implements OnInit {
	public static button: SubmitButtonComponent;
	
	@Input() public class: string = 'btn btn-primary';
	@Input() public isLoading: boolean;
	@Input() public disabled: boolean;

	@Output() public onClick = new EventEmitter();
	constructor() { 
		SubmitButtonComponent.button = this;
	}

	public ngOnInit(): void {

	}

	public click() {
		this.onClick.emit();
	}

}

