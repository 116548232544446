<div appFadeIn class="card">
	<div class="card-header">
		<h4 class="card-title">{{ title | translate }}</h4>
	</div>
	<div class="card-body">
		<text-input [formGroup]="form" [name]="'name'" [label]="'admin.tenantName' | translate"></text-input>
		<label for="products">Available Products</label>		
		<div class="mb-2" [formGroup]="form">
			<p-multiSelect id="products" 
				inputId="products" 
				[filter]="false" 
				display="chip" 
				[options]="products?.data" 
				formControlName="assignedProducts" 
				optionLabel="name" 
				optionValue="id" 
				placeholder="Select">
			</p-multiSelect>
		</div>
		<div>
			<submit-button [isLoading]="isLoading" (onClick)="onSubmit()">{{ 'general.save' | translate }}</submit-button>
			<button class="btn btn-link" (click)="cancel()">{{ 'general.cancel' | translate }}</button>
		</div>
	</div>
</div>