import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, FormGroup, Validators } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { Translation } from '../../translations/translate.service';

@Component({
	selector: 'multiselect-input',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		CommonModule,
		MultiSelectModule
	],
	templateUrl: './multiselect-input.component.html',
	styleUrl: './multiselect-input.component.scss'
})
export class MultiselectInputComponent {
	@Input() public formGroup: FormGroup;
	@Input() public name: string;
	@Input() public label: string;
	@Input() public valueField = 'id';
	@Input() public textField = 'name';
	@Input() public data: any[];
	@Input() public disabled: boolean;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	@Input() public itemDisabled = (...args) => false;
	@Output() public valueChange = new EventEmitter();

	public isRequired: boolean;
	public defaultItem: any;

	constructor() { }

	public ngOnInit(): void {
		this.isRequired = this.formGroup.get(this.name).hasValidator(Validators.required);

		const defaultItem = {};
		defaultItem[this.valueField] = null;
		defaultItem[this.textField] = `${!this.label && this.isRequired ? (Translation.getResource('general.required') + ' *') : 'Select'} ${this.label ?? ''}`;
		this.defaultItem = defaultItem;
		this.data?.map((item) => {
			item.disabled = this.itemDisabled(item);
		});
	}

	public onValueChange(event: any) {
		this.valueChange.emit(event);
	}
}
