{
	"layout": {
		"dashboard": "Nadzorna ploča",
		"requests": "Zahtjevi",
		"createRequests": "Kreiraj zahtjev",
		"viewRequests": "Pregledaj zahtjeve",
		"trips": "Putovanja",
		"createTrips": "Kreiraj putovanje",
		"viewTrips": "Pregledaj putovanja",
		"adminSettings": "Postavke administratora",
		"tenantSettings": "Postavke najmoprimca",
		"userSettings": "Postavke korisnika",
		"configuration": "Konfiguracija",
		"reports": "Izvještaji"
	},
	"general": {
		"loading": "Učitavanje...",
		"save": "Spremi",
		"validate": "Validiraj",
		"validateAddress": "Validiraj adresu",
		"saveForLater": "Spremi za kasnije",
		"cancel": "Odustani",
		"ok": "Ok",
		"yes": "Da",
		"no": "Ne",
		"areYouSure": "Jeste li sigurni?",
		"information": "Informacije",
		"warning": "Upozorenje",
		"error": "Greška",
		"open": "Otvori",
		"edit": "Uredi",
		"delete": "Obriši",
		"exportToPdf": "Izvezi u PDF",
		"exportToExcel": "Izvezi u Excel",
		"saveAsPdf" : "Spremi kao PDF",
		"details": "Detalji",
		"summary": "Sažetak",
		"info": "Info",
		"success": "Uspjeh",
		"editDetails": "Uredi detalje",
		"savedSuccessfully": "Uspješno spremljeno",
		"updatedSuccessfully": "Uspješno ažurirano",
		"deletedSuccessfully": "Uspješno obrisano",
		"invalidForm": "Neki podaci nisu valjani. Molimo pregledajte i pokušajte ponovo.",
		"required": "Obavezno",
		"concrete": "Betonsko",
		"submit": "Podnesi",
		"somethingWentWrong": "Nešto je pošlo po zlu",
		"optional": "Opcionalno",
		"view": "Pregledaj",
		"switchTenant": "Promijeni najmoprimca",
		"logout": "Odjava",
		"unsavedChanges": "Imate nespremljene promjene. Jeste li sigurni da želite napustiti ovu stranicu?",
        "prev": "Prethodno",
        "next": "Sljedeće",
		"viewOnMap": "Pogledaj na karti",
		"reset": "Resetiraj",
		"select": "Odaberi",
		"monday": "Ponedjeljak",
		"tuesday": "Utorak",
		"wednesday": "Srijeda",
		"thursday": "Četvrtak",
		"friday": "Petak",
		"saturday": "Subota",
		"sunday": "Nedjelja",
		"january": "Siječanj",
		"february": "Veljača",
		"march": "Ožujak",
		"april": "Travanj",
		"may": "Svibanj",
		"june": "Lipanj",
		"july": "Srpanj",
		"august": "Kolovoz",
		"september": "Rujan",
		"october": "Listopad",
		"november": "Studeni",
		"december": "Prosinac",
		"noAddressesFound": "Nema pronađenih adresa",
		"showing": "Prikazuje se",
		"of": "od",
		"entries": "zapisa",
		"percentage": "Postotak"
	},
	"login": {
		"emailAddress": "Email adresa",
		"password": "Lozinka",
		"login": "Prijava",
		"forgotPassword": "Zaboravljena lozinka",
		"selectTenant": "Najmoprimac",
		"continue": "Nastavi",
		"title1": "Dobrodošli u Re.Auth!",
		"title2": "Odaberite aplikaciju i unesite vaš email i lozinku!",
		"title3": "Unesite vaš email i lozinku!"
	},
	"request": {
		"requestList": "Popis zahtjeva",
		"newRequest": "Novi zahtjev",
		"name": "Ime",
		"appointmentDate": "Datum sastanka",
		"phoneNumber": "Broj telefona",
		"generalInformation": "Opće informacije",
		"addressInformation": "Informacije o adresi",
		"location": "Pregled lokacije",
		"address": "Adresa",
		"city": "Grad",
		"zip": "Poštanski broj",
		"state": "Država",
		"service": "Usluga",
		"saveSuccess": "Zahtjev uspješno spremljen",
		"tripInformation": "Informacije o putovanju",
		"status": "Status",
		"assignedToTrip": "Dodijeljen putovanju",
		"assignedVehicle": "Dodijeljeno vozilo",
		"tripStatus": "Status putovanja",
		"requestStatus": "Status zahtjeva",
		"setCustomDuration": "Postavi prilagođenu trajanje",
		"duration": "Trajanje",
		"comment": "Komentar",
		"cancelRequest": "Otkaži zahtjev",
		"requestCanceled": "Zahtjev otkazan",
		"appointmentType": "Vrsta sastanka",
		"oneTime": "Jednokratno",
		"recurring": "Ponavljajuće",
		"selectDays": "Odaberi dane",
		"numberOfWeeks": "Broj tjedana",
		"startDate": "Datum početka",
		"invoiceRequired": "Potrebna faktura",
		"recurringRequest": "Ponavljajući zahtjev"
	},
	"admin": {
		"tenantSettings": "Postavke najmoprimca",
		"tenantName": "Ime najmoprimca",
		"tenantAdmin": "Administrator najmoprimca",
		"tenantAddNew": "Dodaj novog najmoprimca",
		"tenantEdit": "Uredi najmoprimca",
		"tenantDelete": "Obriši najmoprimca",
		"tenantDeleteConfirm": "Jeste li sigurni da želite obrisati ovog najmoprimca?",
		"tenantDeleteSuccess": "Najmoprimac uspješno obrisan",
		"tenantUpdateSuccess": "Najmoprimac uspješno ažuriran",
		"tenantCreateSuccess": "Najmoprimac uspješno kreiran",
		"tenantNew": "Novi najmoprimac",
		"userSettings": "Postavke korisnika",
		"userName": "Korisničko ime",
		"userAdmin": "Administrator korisnika",
		"userAddNew": "Dodaj novog korisnika",
		"userEdit": "Uredi korisnika",
		"userDelete": "Obriši korisnika",
		"userDeleteConfirm": "Jeste li sigurni da želite obrisati ovog korisnika?",
		"userDeleteSuccess": "Korisnik uspješno obrisan",
		"userUpdateSuccess": "Korisnik uspješno ažuriran",
		"userCreateSuccess": "Korisnik uspješno kreiran",
		"userNew": "Novi korisnik",
		"userFirstName": "Ime",
		"userLastName": "Prezime",
		"userEmail": "Email",
		"userRole": "Uloga",
		"userTenant": "Najmoprimac",
		"userConfirmed": "Email potvrđen",
		"selectTenantAdmins": "Odaberi administratore najmoprimca",
		"setTenantAdmin": "Postavi administratora najmoprimca",
		"password": "Lozinka",
		"confirmPassword": "Potvrdi lozinku",
		"configuration": "Konfiguracija",
		"serviceAlreadyAdded": "Usluga s istim imenom već dodana",
		"dailyCapacity": "Dnevni kapacitet",
		"tripCapacity": "Kapacitet putovanja",
		"serviceName": "Naziv usluge",
		"services": "Usluge",
		"configurationSaved": "Konfiguracija uspješno spremljena",
		"generalInformation": "Opće informacije",
		"defaultVehicles": "Zadana vozila",
		"noDefaultVehiclesDefined": "Nisu definirana zadana vozila",
		"addService": "Dodaj uslugu",
		"serviceDuration": "Trajanje usluge (u minutama)",
		"serviceType": "Vrsta usluge",
		"frequentLocations": "Česte lokacije",
		"addLocation": "Dodaj lokaciju",
		"noConfiguration": "Uredite postavke prije nego počnete koristiti aplikaciju.",
		"noServices": "Dodajte barem jednu uslugu.",
		"noVehicles": "Dodajte barem jedno vozilo.",
		"editService": "Uredi uslugu",
		"addressNotValidated": "Adresa nije validirana",
		"addressValidated": "Adresa je validirana",
		"invalidAddress": "Nevažeća adresa",
		"setAddressToDefault": "Zadana adresa",
		"editLocation": "Uredi lokaciju",
		"vehicleBounds": "Granice vozila",
		"selectVehicle": "Odaberi vozilo",
		"resetVehiclePolygonWarning": "Jeste li sigurni da želite resetirati granice vozila?",
		"vehiclePolygonsSuccessfullySaved": "Teritorij vozila uspješno spremljen",
		"territoryColor": "Boja teritorija",
		"locationDeleted": "Lokacija uspješno obrisana",
		"locationSuccessfullySaved": "Lokacija uspješno spremljena",
		"undoAllChanges": "Poništi sve promjene",
		"price": "Cijena",
		"description": "Opis"
	},
	"trip": {
		"selectDate": "Odaberi datum",
		"tripDate": "Datum putovanja",
		"vehicles": "Vozila",
		"trips": "Putovanja",
		"tripOverview": "Pregled putovanja",
		"optimized": "Optimizirano",
		"optimizePreview": "Optimiziraj i pregledaj",
		"manualAssign": "Ručna dodjela",
		"addTrip": "Dodaj putovanje",
		"saveTrips": "Spremi putovanja",
		"showUnassignedRequests": "Prikaži nepridružene zahtjeve",
		"noRequests": "Nema zahtjeva u putovanju.",
		"noUnassignedRequests": "Nema nepridruženih zahtjeva.",
		"trip": "Putovanje",
		"unassignedRequests": "Nepridruženi zahtjevi",
		"noTripsMessage": "Nema putovanja za taj datum.",
		"viewRoute": "Pogledaj rutu",
		"assignVehicle": "Dodijeli vozilo",
		"vehicle": "Vozilo",
		"noVehicleAssigned": "Neka putovanja nemaju dodijeljena vozila",
		"noRequestsAssigned": "Neka putovanja nemaju dodijeljene zahtjeve",
		"selectVehicles": "Odaberi vozila",
		"selectService": "Odaberi uslugu",
		"tripWithDeliveryValidation": "Ovo putovanje ima zahtjeve označene za dostavu. Molimo dodajte svoju lokaciju dostave.",
		"tripsWithDeliveryValidation": "Neka putovanja imaju zahtjeve označene za dostavu, ali lokacija dostave nije dodana.",
		"deliveryAfterRequest": "Zahtjevi označeni za dostavu trebaju biti prije lokacije dostave.",
		"tripCapacityWarning": "Kapacitet putovanja premašuje postavljeni kapacitet u konfiguraciji.",
		"tripCapacityWarningConfirm": "Neka putovanja imaju kapacitet koji premašuje postavljeni kapacitet u konfiguraciji. Želite li nastaviti?",
		"saveSuccess": "Putovanja uspješno spremljena",
		"date": "Datum",
		"status": "Status",
		"distance": "Udaljenost",
		"duration": "Trajanje",
		"requestsInformation": "Informacije o zahtjevima",
		"generalInformation":"Opće informacije",
		"list": "Lista",
		"reoptimize": "Reoptimiziraj",
		"reoptimizeWarning": "Jeste li sigurni da želite reoptimizirati putovanja? Sve promjene bit će odbačene.",
		"deleteSuccess": "Putovanje uspješno obrisano",
		"addLocation": "Dodaj lokaciju",				
		"deleteTrip": "Obriši putovanje",
		"tripsOverview": "Pregled putovanja",
		"completed": "Dovršeno",
		"pending": "U pripravnosti"
	},
	"vehicle": {
		"addVehicle": "Dodaj vozilo",
		"editVehicle": "Uredi vozilo",
		"vehicleName": "Naziv vozila",
		"selectUser": "Odaberi korisnika",
		"userName": "Korisničko ime",
		"markAsPrimary": "Primarno",
		"noUsers": "Vozilo nema korisnika",
		"noPrimaryUser": "Vozilo nema primarnog korisnika",
		"primaryUser": "Primarni korisnik",
		"travelMode": "Način putovanja",
		"disabledDefaultAddress": "Onemogućeno jer zadana adresa nije postavljena",
		"vehicleDeleted": "Vozilo uspješno obrisano",
		"primaryUserRemove": "Ne možete ukloniti primarnog korisnika. Postavite drugog korisnika kao primarnog i pokušajte ponovo.",
		"userRemoved": "Korisnik uspješno uklonjen",
		"savedSuccessfully": "Vozilo uspješno spremljeno",
		"carMake": "Marka automobila",
		"plateNumber": "Registarski broj automobila",
		"onlyOneUserWalkingMode": "Samo jedan korisnik može biti postavljen u načinu hodanja",
		"hasFutureTrips": "Vozilo ima putovanja koja nisu završena, brisanje vozila će izbrisati putovanja. Želite li nastaviti?",
		"selectTravelMode": "Molimo odaberite način putovanja."
	},
    "dashboard": {
        "numberOfRequests": "Broj zahtjeva po mjesecu",
        "requestsPerDriver": "Zahtjevi po vozaču",
        "servicesPercentage": "Postotak usluga",
        "requestStatuses": "Statusi zahtjeva",
		"serviceEarnings": "Zarada po usluzi"
    },
	"reports": {
		"generate": "Generiraj",
		"viewReports": "Pregledaj izvještaje",
		"filter": "Filter",
		"year": "Godina",
		"month": "Mjesec",
		"monthlyReport": "Mjesečni izvještaj",
		"mileageReport": "Izvještaj o kilometraži",
		"employee": "Zaposlenik",
		"monthlyHourReport": "Mjesečni izvještaj sati",
		"patientName": "Ime i prezime pacijenta",
		"service": "Usluga",
		"patientTotal": "Ukupno pacijent",
		"saturdayAbb": "SUB",
		"sundayAbb": "NED",
		"monthTotal": "Ukupno mjesec",
		"vacation": "Godišnji odmor",
		"sickLeave": "Bolovanje",
		"downloadExcel": "Preuzmi Excel",
		"date": "Datum",
		"monthlyReportSaved": "Mjesečni izvještaj uspješno spremljen",
		"odometerStart": "Početno stanje brojila",
		"odometerEnd": "Završno stanje brojila",
		"mileage": "Broj prijeđenih kilometara",
		"systemCalculatedMileage": "Sustavno izračunata kilometraža",
		"systemMileageInfo": "Ovo predstavlja sustavno ukupno izračunatu kilometražu koja je vidljiva samo administratorima. Ova vrijednost služi za usporedbu s upisanim vrijednostima.",
		"locationName": "Naziv lokacije",
		"workReport": "Izvješće o radu",
		"fullName": "Ime i prezime",
		"carMake": "Marka automobila",
		"plateNumber": "Registarski broj automobila", 
		"total": "Ukupno",
		"sumEarned": "Ukupna zarada"
	}
}