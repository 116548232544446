import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
	selector: 'checkbox-input',
	standalone: true,
	imports: [CommonModule, CheckboxModule, ReactiveFormsModule],
	templateUrl: './checkbox-input.component.html',
	styleUrl: './checkbox-input.component.scss'
})
export class CheckboxInputComponent implements OnInit {
	@Input() public formGroup: FormGroup;
	@Input() public name: string;
	@Input() public label: string;
	@Input() public disabled = false;
	@Output() public valueChange = new EventEmitter();
	public isRequired: boolean;

	constructor() {
	}

	public ngOnInit(): void {
		this.isRequired = this.formGroup.get(this.name).hasValidator(Validators.required);
	}

	public onValueChange(event: any) {
		this.valueChange.emit(event);
	}
}
