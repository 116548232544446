{
	"layout": {
		"dashboard": "Dashboard",
		"requests": "Requests",
		"createRequests": "Create Request",
		"viewRequests": "View Requests",
		"trips": "Trips",
		"createTrips": "Create Trips",
		"viewTrips": "View Trips",
		"adminSettings": "Admin Settings",
		"tenantSettings": "Tenant Settings",
		"userSettings": "User Settings",
		"configuration": "Configuration",
		"reports": "Reports"
	},
	"general": {
		"loading": "Loading...",
		"save": "Save",
		"validate": "Validate",
		"validateAddress": "Validate Address",
		"saveForLater": "Save for later",
		"cancel": "Cancel",
		"ok": "Ok",
		"yes": "Yes",
		"no": "No",
		"areYouSure": "Are you sure?",
		"information": "Information",
		"warning": "Warning",
		"error": "Error",
		"open": "Open",
		"edit": "Edit",
		"delete": "Delete",
		"exportToPdf": "Export to PDF",
		"exportToExcel": "Export to Excel",
		"saveAsPdf" : "Save as PDF",
		"details": "Details",
		"summary": "Summary",
		"info": "Info",
		"success": "Success",
		"editDetails": "Edit details",
		"savedSuccessfully": "Saved successfully",
		"updatedSuccessfully": "Updated successfully",
		"deletedSuccessfully": "Deleted successfully",
		"invalidForm": "Some fields are not valid. Please review and try again.",
		"required": "Required",
		"concrete": "Concrete",
		"submit": "Submit",
		"somethingWentWrong": "Something went wrong",
		"optional": "Optional",
		"view": "View",
		"switchTenant": "Switch Tenant",
		"logout": "Logout",
		"unsavedChanges": "You have unsaved changes. Are you sure you want to leave this page?",
        "prev": "Previous",
        "next": "Next",
		"viewOnMap": "View on map",
		"reset": "Reset",
		"select": "Select",
		"monday": "Monday",
		"tuesday": "Tuesday",
		"wednesday": "Wednesday",
		"thursday": "Thursday",
		"friday": "Friday",
		"saturday": "Saturday",
		"sunday": "Sunday",
		"january": "January",
		"february": "February",
		"march": "March",
		"april": "April",
		"may": "May",
		"june": "June",
		"july": "July",
		"august": "August",
		"september": "September",
		"october": "October",
		"november": "November",
		"december": "December",
		"noAddressesFound": "No addresses found",
		"showing": "Showing",
		"of": "of",
		"entries": "entries",
		"percentage": "Percentage",
		"reactivate": "Reactivate"
	},
	"login": {
		"emailAddress": "Email address",
		"password": "Password",
		"login": "Log In",
		"forgotPassword": "Forgot password",
		"selectTenant": "Tenant",
		"continue": "Continue",
		"selectApplication": "Select application",
		"sendLinkToEmail": "Send link to email",
		"resetPassword": "Reset password",
		"title1": "Welcome to Re.Auth!",
		"title2": "Please select application and enter your email and password!",
		"title3": "Please enter your email and password!"
	},
	"request": {
		"requestList": "Request list",
		"newRequest": "New request",
		"name": "Name",
		"appointmentDate": "Appointment date",
		"phoneNumber": "Phone number",
		"generalInformation": "General information",
		"addressInformation": "Address information",
		"location": "Location preview",
		"address": "Address",
		"city": "City",
		"zip": "Zip code",
		"state": "State",
		"service": "Service",
		"saveSuccess": "Request saved successfully",
		"tripInformation": "Trip information",
		"status": "Status",
		"assignedToTrip": "Assigned to trip",
		"assignedVehicle": "Assigned vehicle",
		"tripStatus": "Trip status",
		"requestStatus": "Request status",
		"setCustomDuration": "Set custom duration",
		"duration": "Duration",
		"comment": "Comment",
		"cancelRequest": "Cancel request",
		"requestCanceled": "Request canceled",
		"appointmentType": "Appointment type",
		"oneTime": "One time",
		"recurring": "Recurring",
		"selectDays": "Select days",
		"numberOfWeeks": "Number of weeks",
		"startDate": "Start date",
		"invoiceRequired": "Invoice required",
		"recurringRequest": "Recurring request"
	},
	"admin": {
		"tenantSettings": "Tenant Settings",
		"tenantName": "Tenant Name",
		"tenantAdmin": "Tenant Admin",
		"tenantAddNew": "Add New Tenant",
		"tenantEdit": "Edit Tenant",
		"tenantDelete": "Delete Tenant",
		"tenantDeleteConfirm": "Are you sure you want to delete this tenant?",
		"tenantDeleteSuccess": "Tenant deleted successfully",
		"tenantUpdateSuccess": "Tenant updated successfully",
		"tenantCreateSuccess": "Tenant created successfully",
		"tenantNew": "New Tenant",
		"userSettings": "User Settings",
		"userName": "User Name",
		"userAdmin": "User Admin",
		"userAddNew": "Add New User",
		"userEdit": "Edit User",
		"userDelete": "Delete User",
		"userDeleteConfirm": "Are you sure you want to delete this user?",
		"userDeleteSuccess": "User deleted successfully",
		"userUpdateSuccess": "User updated successfully",
		"userCreateSuccess": "User created successfully",
		"userNew": "New User",
		"userFirstName": "First Name",
		"userLastName": "Last Name",
		"userEmail": "Email",
		"userRole": "Role",
		"userTenant": "Tenant",
		"userConfirmed": "Email Confirmed",
		"selectTenantAdmins": "Select Tenant Admins",
		"setTenantAdmin": "Set Tenant Admin",
		"password": "Password",
		"confirmPassword": "Confirm Password",
		"configuration": "Configuration",
		"serviceAlreadyAdded": "Service with same name already added",
		"dailyCapacity": "Daily Capacity",
		"tripCapacity": "Trip Capacity",
		"serviceName": "Service Name",
		"services": "Services",
		"configurationSaved": "Configuration saved successfully",
		"generalInformation": "General information",
		"defaultVehicles": "Default Vehicles",
		"noDefaultVehiclesDefined": "No default vehicles defined",
		"addService": "Add Service",
		"serviceDuration": "Service Duration (in minutes)",
		"serviceType": "Service Type",
		"frequentLocations": "Frequent Locations",
		"addLocation": "Add Location",
		"noConfiguration": "Please set up your settings before you start using the application.",
		"noServices": "Please add at least one service.",
		"noVehicles": "Please add at least one vehicle.",
		"editService": "Edit Service",
		"addressNotValidated": "Address not validated",
		"addressValidated": "Address validated",
		"invalidAddress": "Invalid address",
		"setAddressToDefault": "Default Address",
		"editLocation": "Edit Location",
		"vehicleBounds": "Vehicle Bounds",
		"selectVehicle": "Select Vehicle",
		"resetVehiclePolygonWarning": "Are you sure you want to reset the vehicle bounds?",
		"vehiclePolygonsSuccessfullySaved": "Vehicle territory successfully saved",
		"territoryColor": "Territory Color",
		"locationDeleted": "Location deleted successfully",
		"locationSuccessfullySaved": "Location successfully saved",
		"undoAllChanges": "Undo all changes",
		"price": "Price",
		"description": "Description",
		"active": "Active",
		"userReactivateConfirm": "Are you sure you want to reactivate this user?",
		"userReactivateSuccess": "User reactivated successfully",
		"product": "Product",
		"products": "Products",
		"userProducts": "User Products"
	},
    "trip": {
        "selectDate": "Select date",
        "tripDate": "Trip date",
		"vehicles": "Vehicles",
		"trips": "Trips",
		"tripOverview": "Trip Overview",
		"optimized": "Optimized",
        "optimizePreview": "Optimize and preview",
        "manualAssign": "Manual assign",
        "addTrip": "Add trip",
        "saveTrips": "Save trips",
        "showUnassignedRequests": "Show unassigned requests",
        "noRequests": "No requests in trip.",
        "noUnassignedRequests": "No unassigned requests.",
        "trip": "Trip",
        "unassignedRequests": "Unassigned Requests",
        "noTripsMessage": "No trips for this date.",
		"viewRoute": "View Route",
		"assignVehicle": "Assign Vehicle",
		"vehicle": "Vehicle",
		"noVehicleAssigned": "Some trips have unassigned vehicles",
		"noRequestsAssigned": "Some trips have unassigned requests",
		"selectVehicles": "Select Vehicles",
		"selectService": "Select Service",
		"tripWithDeliveryValidation": "This trip has requests which are marked for delivery. Please add your delivery location.",
		"tripsWithDeliveryValidation": "Some trips have requests which are marked for delivery but the the delivery location is not added.",
		"deliveryAfterRequest": "The requests which are marked for delivery should be before the delivery location.",
		"tripCapacityWarning": "The trip capacity exceeds the capacity set in the configuration.",
		"tripCapacityWarningConfirm": "Some trips have capacity which exceed the capacity set in the configuration. Do you want to continue?",
		"saveSuccess": "Trips saved successfully",
		"date": "Date",
		"status": "Status",
		"distance": "Distance",
		"duration": "Duration",
		"requestsInformation": "Requests Information",
		"generalInformation":"General information",
		"list": "List",
		"reoptimize": "Reoptimize",
		"reoptimizeWarning": "Are you sure you want to reoptimize the trips? All changes will be discarded.",
		"deleteSuccess": "Trip deleted successfully",
		"addLocation": "Add Location",				
		"deleteTrip": "Delete Trip",
		"tripsOverview": "Trips Overview",
		"completed": "Completed",
		"pending": "Pending"
    },
	"vehicle": {
		"addVehicle": "Add vehicle",
		"editVehicle": "Edit vehicle",
		"vehicleName": "Vehicle Name",
		"selectUser": "Select User",
		"userName": "User Name",
		"markAsPrimary": "Primary",
		"noUsers": "Vehicle has no users",
		"noPrimaryUser": "Vehicle has no primary user",
		"primaryUser": "Primary User",
		"travelMode": "Travel Mode",
		"disabledDefaultAddress": "Disabled because the default address is not set",
		"vehicleDeleted": "Vehicle deleted successfully",
		"primaryUserRemove": "You cannot remove the primary user. Please set another user as primary and try again.",
		"userRemoved": "User removed successfully",
		"savedSuccessfully": "Vehicle saved successfully",
		"carMake": "Car Make",
		"plateNumber": "Plate Number",
		"onlyOneUserWalkingMode": "Only one user can be set in walking mode",
		"hasFutureTrips": "Vehicle has trips which are not completed, deleting the vehicle will the trips. Do you want to continue?",
		"selectTravelMode": "Please select travel mode."
	},
    "dashboard": {
        "numberOfRequests": "Number of requests per month",
        "requestsPerDriver": "Requests per driver",
        "servicesPercentage": "Services percentage",
        "requestStatuses": "Requests statuses",
		"serviceEarnings": "Service earnings"
    },
	"reports": {
		"generate": "Generate",
		"viewReports": "View Reports",
		"filter": "Filter",
		"year": "Year",
		"month": "Month",
		"monthlyReport": "Monthly Report",
		"mileageReport": "Kilometrage Report",
		"employee": "Employee",
		"monthlyHourReport": "Monthly Hour Report",
		"patientName": "Patient Full Name",
		"service": "Service",
		"patientTotal": "Patient Total",
		"saturdayAbb": "SAT",
		"sundayAbb": "SUN",
		"monthTotal": "Month Total",
		"vacation": "Vacation",
		"sickLeave": "Sick Leave",
		"downloadExcel": "Download Excel",
		"date": "Date",
		"monthlyReportSaved": "Monthly report saved successfully",
		"odometerStart": "Odometer Start Value",
		"odometerEnd": "Odometer End Value",
		"mileage": "Kilometrage",
		"systemCalculatedMileage": "System Calculated Kilometrage",
		"systemMileageInfo": "This is the total distance calculated by the system and it's only visible to the admin. It's used to compare entered and calculated mileage.",
		"locationName": "Location Name",
		"workReport": "Work Report",
		"fullName": "Full Name",
		"carMake": "Car Make",
		"plateNumber": "Plate Number",
		"total": "Total",
		"sumEarned": "Sum Earned"
	}
}