import { Message } from 'primeng/api';
import { AppComponent } from '../../app.component';


export class Notification {

	private static notificationSettings: Message = {
		life: 2400,
		key: 'tc'
	};

	public static showDefault(message: string): void {
		this.notificationSettings.detail = message;
		this.notificationSettings.severity = 'info';

		AppComponent.app.notificationService.add(this.notificationSettings);
	}

	public static showSuccess(message: string): void {
		this.notificationSettings.detail = message;
		this.notificationSettings.severity = 'success';

		AppComponent.app.notificationService.add(this.notificationSettings);
	}

	public static showWarning(message: string): void {
		this.notificationSettings.detail = message;
		this.notificationSettings.severity = 'warning';

		AppComponent.app.notificationService.add(this.notificationSettings);
	}

	public static showInfo(message: string): void {
		this.notificationSettings.detail = message;
		this.notificationSettings.severity = 'info';

		AppComponent.app.notificationService.add(this.notificationSettings);
	}

	public static showError(message: string): void {
		this.notificationSettings.detail = message;
		this.notificationSettings.severity = 'error';

		AppComponent.app.notificationService.add(this.notificationSettings);
	}
}