import { Component } from '@angular/core';

@Component({
	selector: 'app-product-edit',
	standalone: true,
	imports: [],
	templateUrl: './product-edit.component.html',
	styleUrl: './product-edit.component.scss'
})
export class ProductEditComponent {

}
