<div appFadeIn class="card">
	<div class="card-header">
		{{ title | translate }}
	</div>
	<div class="card-body">
		<dropdown-input [formGroup]="form" [name]="'tenantId'" [label]="'admin.userTenant' | translate"
			[data]="tenants$ | async" (valueChange)="onTenantSelect($event)">
		</dropdown-input>

		<div class="card card-body mb-2">
			<div class="d-flex justify-content-start align-items-end">
				<label for="">{{ 'admin.userProducts' | translate }}</label>
				<button [disabled]="!this.form.get('tenantId').value || userProducts?.controls?.length === products?.length" class="btn badge badge-sm rounded-pill bg-app p-2 ms-2" (click)="addUserProduct()"><i class="fas fa-plus"></i></button>
			</div>
			<hr>
			<div *ngFor="let userProduct of userProducts.controls; let i = index" class="row">
				<div class="col-md-5">
					<dropdown-input [disabled]="!form.get('tenantId').value" [itemDisabled]="productDisabled" [formGroup]="$any(userProduct)" [name]="'productId'" [label]="'admin.product' | translate"
						[data]="products">
					</dropdown-input>
				</div>
				<div class="col-md-5">
					<dropdown-input [formGroup]="$any(userProduct)" [name]="'role'" [label]="'admin.userRole' | translate" [valueField]="'name'"
						[data]="roleDropdown$ | async">
					</dropdown-input>
				</div>
				<div class="col-md-1 align-items-center d-flex">
					<button class="btn badge badge-sm rounded-pill bg-danger p-3 ms-2 mt-3" (click)="removeUserProduct(i)"><i class="fas fa-times"></i></button>
				</div>
			</div>
		</div>

		<text-input [formGroup]="form" [name]="'firstName'" [label]="'admin.userFirstName' | translate">
		</text-input>
		<text-input [formGroup]="form" [name]="'lastName'" [label]="'admin.userLastName' | translate">
		</text-input>
		<text-input [formGroup]="form" [name]="'email'" [label]="'admin.userEmail' | translate">
		</text-input>
		<password-input *ngIf="!userId" [formGroup]="form" [name]="'password'" [label]="'admin.password' | translate">
		</password-input>
		<password-input *ngIf="!userId" [formGroup]="form" [name]="'confirmPassword'"
			[label]="'admin.confirmPassword' | translate">
		</password-input>
		<checkbox-input [formGroup]="form" [name]="'emailConfirmed'" [label]="'admin.userConfirmed' | translate">
		</checkbox-input>
		<div>
			<submit-button [isLoading]="isLoading" (onClick)="onSubmit()">{{ 'general.save' | translate
				}}</submit-button>
			<button class="btn btn-link d-inline-block" (click)="cancel()">{{ 'general.cancel' | translate }}</button>
		</div>
	</div>
</div>