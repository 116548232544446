import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CheckboxInputComponent } from '../../../shared/inputs/checkbox-input/checkbox-input.component';
import { DropdownInputComponent } from '../../../shared/inputs/dropdown-input/dropdown-input.component';
import { PasswordInputComponent } from '../../../shared/inputs/password-input/password-input.component';
import { SubmitButtonComponent } from '../../../shared/inputs/submit-button/submit-button.component';
import { TextInputComponent } from '../../../shared/inputs/text-input/text-input.component';
import { TranslatePipe } from '../../../shared/translations/translate.pipe';
import { Subscription, take } from 'rxjs';
import { DialogService } from '../../../core/services/dialog.service';
import { CreateTenantCommand, TenantDto, TenantService, UpdateTenantCommand } from '../../../shared/generated';
import { FormGroupHelper } from '../../../shared/helpers/form-group.helper';
import { Translation } from '../../../shared/translations/translate.service';
import { ControlsOf } from '../../../shared/types/control-of.type';
import { Notification } from '../../../core/services/notification.service';
import { getDefaultGridState } from '../../../shared/helpers/grid.helper';
import { MultiSelectModule } from 'primeng/multiselect';
import { FadeInDirective } from '../../../shared/directives/fade-in.directive';
import { ProductService } from '../../../shared/generated/api/product.service';
import { ProductDtoGridData } from '../../../shared/generated/model/productDtoGridData';

@Component({
	selector: 'app-tenant-edit',
	standalone: true,
	imports: [
		CommonModule,
		DropdownInputComponent,
		TextInputComponent,
		ReactiveFormsModule,
		PasswordInputComponent,
		TranslatePipe,
		SubmitButtonComponent,
		RouterModule,
		CheckboxInputComponent,
		MultiSelectModule,
		FadeInDirective
	],
	templateUrl: './tenant-edit.component.html',
	styleUrl: './tenant-edit.component.scss'
})
export class TenantEditComponent implements OnInit, OnDestroy {
	public tenantId: number;
	public title = 'admin.tenantNew';
	public form: FormGroup<ControlsOf<TenantDto>>;
	private subscriptions: Subscription[] = [];
	public isLoading: boolean;
	private initialData: string;
	public products: ProductDtoGridData;

	constructor(private route: ActivatedRoute, private tenantService: TenantService, private router: Router, private productService: ProductService) { }

	public ngOnInit() {
		this.tenantId = +this.route.snapshot.paramMap.get('id');
		this.title = this.tenantId ? 'admin.tenantEdit' : 'admin.tenantNew';

		this.initForm();
		this.productService.getAllProductsForGrid(getDefaultGridState(20)).pipe(take(1)).subscribe(res => {
			this.products = res;
		});
		if (this.tenantId) {
			this.getTenant();
		}
	}

	private initForm() {
		this.form = new FormGroup<ControlsOf<TenantDto>>({
			id: new FormControl(0),
			name: new FormControl(null, Validators.required),
			assignedProducts: new FormControl(null) as any
		});
		this.setInitialValue();
	}

	private getTenant() {
		this.subscriptions.push(
			this.tenantService.getTenant(this.tenantId).subscribe(tenant => {
				this.form.patchValue(tenant);
				this.setInitialValue();
			})
		);
	}

	private setInitialValue() {
		this.initialData = JSON.stringify(this.form.value);
	}

	public onSubmit() {
		if (this.form.invalid) {
			FormGroupHelper.markInvalidFields(this.form);
			return;
		}
		FormGroupHelper.attachFormGroup(this.form);
		DialogService.areYouSureDialog(
			() => {
				this.isLoading = true;
				if (this.tenantId) {
					this.updateTenant();
					return;
				}
				this.createTenant();
			}
		);
	}

	private updateTenant() {
		const tenant = this.form.value as UpdateTenantCommand;

		this.subscriptions.push(
			this.tenantService.updateTenant(this.tenantId, tenant).subscribe({
				next: () => {
					Notification.showSuccess(Translation.getResource('admin.tenantUpdateSuccess'));
					this.router.navigate(['../'], { relativeTo: this.route });
				},
				error: () => this.isLoading = false })
		);
	}

	private createTenant() {
		const tenant = this.form.value as CreateTenantCommand;

		this.subscriptions.push(
			this.tenantService.createTenant(tenant).subscribe({
				next: () => {
					Notification.showSuccess(Translation.getResource('admin.tenantCreateSuccess'));
					this.router.navigate(['../'], { relativeTo: this.route });
				},
				error: () => this.isLoading = false
			})
		);
	}

	public cancel() {
		this.router.navigate(['../'], { relativeTo: this.route });
	}

	public canDeactivate(): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			const currentFormValue = JSON.stringify(this.form.value);
			if (this.initialData !== currentFormValue && !this.isLoading) {
				DialogService.unsavedChangesDialog(
					() => {
						resolve(true);
					},
					() => {
						resolve(false);
					}
				);
			} else {
				resolve(true);
			}
		});
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}
}
