import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { TableFilterEvent, TablePageEvent } from 'primeng/table';
import { SortEvent } from 'primeng/api';
import { FieldFilter } from '../../generated/model/fieldFilter';
import { GridRequest } from '../../generated/model/gridRequest';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { TranslatePipe } from '../../translations/translate.pipe';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ColumnFilterOperatorPipe } from './column-filter-type.pipe';

export interface GridColumn {
	field: string,
	content: string,
	sortable?: boolean,
	filter?: 'text' | 'numeric' | 'date' | 'boolean'	| 'dropdown'
	options?: any[],
	optionValue?: string
}

interface GridData {
	data?: any[] | null;
	total?: number;
}

@Component({
	selector: 'app-grid',
	templateUrl: './grid.component.html',
	styleUrl: './grid.component.scss',
	standalone: true,
	imports: [
		CommonModule, 
		TableModule, 
		TranslatePipe, 
		DropdownModule, 
		FormsModule, 
		ColumnFilterOperatorPipe
	]
})
export class GridComponent {
	public state: GridRequest = {
		skip: 0,
		take: 10
	};
	@Input({ alias: 'data', required: true }) public gridData: GridData = { data: [], total: 0 };
	@Input({ alias: 'columns', required: true }) public columns: GridColumn[] = [];
	@Input({ alias: 'bodyTemplate', required: true }) public bodyTemplate: TemplateRef<any>;
	@Input('masterDetail') public masterDetail: boolean = false;
	@Input('masterDetailTemplate') public masterDetailTemplate: TemplateRef<any>;
	@Input('commandColumn') public commandColumn: boolean = false;
	@Input('commandColumnTemplate') public commandColumnTemplate: TemplateRef<any>;
	@Input('loading') public loading: boolean = false;
	@Input('selectable') public selectable: boolean = false;
	@Input('filterable') public filterable: boolean = false;
	@Output('stateChange') public stateChange = new EventEmitter<GridRequest>();
	public visibleId: number;

	public onPage(event: TablePageEvent) {
		this.state.skip = event.first;
		this.state.take = event.rows;
		this.stateChange.emit(this.state);
	}

	public onSort(event: SortEvent) {
		this.state.orderBy = event.field;
		this.state.ascending = event.order > 0;
		this.state.skip = 0;
		this.state.take = 10;
		this.stateChange.emit(this.state);
	}

	public toggleDetail(id: number) {
		this.visibleId = this.visibleId === id ? null : id;
	}

	public onFilter(event: TableFilterEvent) {
		const filters: FieldFilter[] = Object.keys(event.filters).map(key => {
			return {
				field: key,
				value: event.filters[key].value,
				operator: event.filters[key].matchMode
			};
		}).filter(f => f.value !== null);
		this.state.filter = {
			fieldFilters: filters,
			logic: 'and'
		};
		this.stateChange.emit(this.state);
	}

	public onChange(event) {
	}
}
