import { Injectable } from '@angular/core';
import * as en from './lang/en.json';
import * as hr from './lang/hr.json';

@Injectable({
	providedIn: 'root'
})
export class Translation {

	public static getResource(key: string): string {
		const language = localStorage.getItem('language') ?? 'en';

		const isSupported = language === 'en' || language === 'hr';

		if (isSupported) {
			const translation = language === 'en' ? en : hr;

			return this.getKey((translation as any).default, key);
		}

		const resultNotSupport = 'No support for language';

		return resultNotSupport;
	}

	private static getKey(store: any, key: string): string {

		if (!key || key.indexOf('.') <= 0) {
			return key;
		}
		const keys = key.split('.');
		const obj: any = store[keys[0]];

		if (!obj) {
			return key;
		}
		const ret: string = obj[keys[1]];
		if (!ret) {
			return key;
		}
		return ret;
	}

	public static setLanguage(language: string) {
		localStorage.setItem('language', language);
		window.location.reload();
	}

	public static getLanguage(): string {
		return localStorage.getItem('language') ?? 'en';
	}
}