<div appFadeIn class="rounded mt-3 mx-3">
	<nav class="navbar navbar-expand-lg bg-white rounded-3">
		<div class="container-fluid">
			<a class="navbar-brand" routerLink="/admin/home" routerLinkActive="active">ReSplit:ADMIN</a>
			<button (click)="toggleNav()" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
				aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarNav">
				<ul class="navbar-nav">
					<li class="nav-item">
						<a class="nav-link" aria-current="page" routerLink="/admin/home" routerLinkActive="active">Home</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" routerLink="/admin/tenants" routerLinkActive="active">Tenants</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" routerLink="/admin/products" routerLinkActive="active">Products</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" routerLink="/admin/users" routerLinkActive="active">Users</a>
					</li>
				</ul>
				<div class="d-flex ms-auto pe-2">					
					<a class="btn btn-danger" (click)="logout()">Logout</a>
				</div>
			</div>
		</div>
	</nav>
	<div class="mt-3 content">
		<router-outlet></router-outlet>	
	</div>
</div>

<p-sidebar [(visible)]="sidebarVisible" position="right" [style]="{ width:'60vw' }">
    <ul class="navbar-nav">
		<li class="nav-item">
			<a class="nav-link p-2" (click)="toggleNav()" aria-current="page" routerLink="/admin/home" routerLinkActive="active">Home</a>
		</li>
		<li class="nav-item">
			<a class="nav-link p-2" (click)="toggleNav()" routerLink="/admin/tenants" routerLinkActive="active">Tenants</a>
		</li>
		<li class="nav-item">
			<a class="nav-link p-2" (click)="toggleNav()" routerLink="/admin/products" routerLinkActive="active">Products</a>
		</li>
		<li class="nav-item">
			<a class="nav-link p-2" (click)="toggleNav()" routerLink="/admin/users" routerLinkActive="active">Users</a>
		</li>
	</ul>
	<div class="d-flex ms-auto pe-2">					
		<a class="btn btn-danger" (click)="logout()">Logout</a>
	</div>
</p-sidebar>