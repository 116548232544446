/* eslint-disable @typescript-eslint/no-explicit-any */
import { TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppComponent } from '../../app.component';
import { Translation } from '../../shared/translations/translate.service';

export class DialogService {

	public static yesNoDialog
	(
		title: string,
		message: string,
		ok: ((data: any) => void) = null,
		cancel: ((data: any) => void) = null,
		messageTemplate: TemplateRef<any> = null,
		okDisabled?: boolean
	): void {
		DialogService.showDialog(
			title,
			message,
			Translation.getResource('general.yes'),
			Translation.getResource('general.no'),
			ok,
			cancel,
			messageTemplate,
			450,
			okDisabled
		);
	}

	public static okCancelDialog
	(
		title: string,
		message: string,
		ok: ((data: any) => void) = null,
		cancel: ((data: any) => void) = null,
		messageTemplate: TemplateRef<any> = null
	): void {
		DialogService.showDialog(
			title,
			message,
			Translation.getResource('general.ok'),
			Translation.getResource('general.cancel'),
			ok,
			cancel,
			messageTemplate,
			450,
			null
		);
	}

	public static areYouSureDialog
	(
		ok: ((data: any) => void) = null,
		cancel: ((data: any) => void) = null,
		message: string = null,
		primaryButtonText = 'general.ok'
	): void {
		DialogService.showDialog(
			Translation.getResource('general.information'),
			message ?? Translation.getResource('general.areYouSure'),
			Translation.getResource(primaryButtonText),
			Translation.getResource('general.cancel'),
			ok,
			cancel,
			null,
			450,
			null
		);
	}

	public static unsavedChangesDialog
	(
		ok: ((data: any) => void) = null,
		cancel: ((data: any) => void) = null,
		message: string = null,
		primaryButtonText = 'general.ok'
	): void {
		DialogService.showDialog(
			Translation.getResource('general.warning'),
			message ?? Translation.getResource('general.unsavedChanges'),
			Translation.getResource(primaryButtonText),
			Translation.getResource('general.cancel'),
			ok,
			cancel,
			null,
			450,
			null
		);
	}

	public static okDialog
	(
		title: string,
		message: string,
		ok: ((data: any) => void) = null,
		messageTemplate: TemplateRef<any> = null,
		width: string | number = 400
	): void {
		DialogService.showDialog(
			title,
			message,
			Translation.getResource('general.ok'),
			'',
			ok,
			null,
			messageTemplate,
			width,
			null
		);
	}

	public static saveCancelDialog
	(
		title: string,
		message: string,
		ok: ((data: any) => void) = null,
		cancel: ((data: any) => void) = null,
		messageTemplate: TemplateRef<any> = null,
		width: string | number = 400,
		okDisabled = null
	): void {
		DialogService.showDialog(
			title,
			message,
			Translation.getResource('general.save'),
			Translation.getResource('general.cancel'),
			ok,
			cancel,
			messageTemplate,
			width,
			okDisabled
		);
	}

	public static showDialog
	(
		title: string,
		message: string,
		okTitle: string,
		noTitle: string,
		ok: ((data: any) => void) = null,
		cancel: ((data: any) => void) = null,
		messageTemplate: TemplateRef<any> = null,
		width: string | number = 450,
		okDisabled: boolean
		//  dialogIndex: number=  DialogService.MESSAGE_INDEX
	): void {

		this.openDialog(
			title,
			message,
			okTitle,
			noTitle,
			ok,
			cancel,
			messageTemplate,
			width,
			okDisabled
		);
	}

	private static openDialog
	(
		title: string,
		message: string,
		okTitle: string,
		noTitle: string,
		ok: ((data: any) => void) = null,
		cancel: ((data: any) => void) = null,
		messageTemplate: TemplateRef<any> = null,
		width: string | number = 450,
		okDisabled: boolean
	): void {

		const dialog = this.getAvailableDialog();
		dialog.title = title;
		dialog.message = message;
		dialog.opened = true;
		dialog.okTitle = okTitle;
		dialog.noTitle = noTitle;
		dialog.messageTemplate = messageTemplate;
		dialog.width = width;
		dialog.cancelVisible = !!noTitle;
		dialog.okDisabled = okDisabled;

		const subscriptions: Subscription[] = [];

		subscriptions.push(
			dialog.okClicked.subscribe(
				data => {
					this.closeSubscriptions(subscriptions);
					if (ok) {
						ok(data);
					}

				}
			)
		);

		subscriptions.push(
			dialog.noClicked.subscribe(
				data => {
					this.closeSubscriptions(subscriptions);
					if (cancel) {
						cancel(data);
					}
				}
			)
		);
	}

	private static getAvailableDialog() {
		return [AppComponent.app.messageDialog, AppComponent.app.messageDialog2].find(d => d.opened === false);
	}

	private static closeSubscriptions (subscriptions: Subscription[]) {
		subscriptions.forEach(
			x => {
				x.unsubscribe();
			}
		);
	}
}