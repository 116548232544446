import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { MessageDialogComponent } from './shared/components/message-dialog/message-dialog.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
	@ViewChild('messageDialog') public messageDialog: MessageDialogComponent;
	@ViewChild('messageDialog2') public messageDialog2: MessageDialogComponent;
	public static app: AppComponent;
	constructor(public router: Router, public notificationService: MessageService) {
		AppComponent.app = this;
	}

	public ngOnInit() {
	}
}
