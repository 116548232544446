<div appFadeIn class="card">
	<div class="card-header">
		Users
		<button class="btn badge rounded-pill bg-app p-3 ms-2" routerLink="new"><i class="fas fa-plus"></i></button>
	</div>
	<div class="card-body">
		<app-grid 
			[data]="users$ | async"
			[columns]="gridColumns"
			[bodyTemplate]="body"
			(stateChange)="dataStateChange($event)"
			[loading]="isLoading"
			[filterable]="true"
			[commandColumn]="true"
			[commandColumnTemplate]="commandColumn">
			<ng-template #body let-data>
				<td>
					{{ data.firstName }}
				</td>
				<td>
					{{ data.lastName }}
				</td>
				<td>
					{{ data.email }}
				</td>
				<td>
					<span class="badge bg-app">{{ data.role }}</span>
				</td>
				<td>
					<span class="badge bg-app">{{ data.productNames }}</span>
				</td>
				<td>
					{{ data.tenant }}
				</td>
				<td>
					<i *ngIf="data.emailConfirmed" class="fa-solid fa-circle-check text-success fs-4"></i>
					<i *ngIf="!data.emailConfirmed" class="fa-solid fa-circle-xmark text-danger fs-4"></i>
				</td>
				<td>
					<i *ngIf="data.active" class="fa-solid fa-circle-check text-success fs-4"></i>
					<i *ngIf="!data.active" class="fa-solid fa-circle-xmark text-danger fs-4"></i>
				</td>
			</ng-template>
			<ng-template #commandColumn let-data>
				<button class="btn badge rounded-pill bg-app p-3 mx-1" [routerLink]="data.id"><i class="fas fa-edit"></i></button>
				@if(data.active) {
					<button class="btn badge rounded-pill bg-danger p-3 mx-1" (click)="onDelete(data)"><i class="fas fa-trash"></i></button>
				} @else {
					<button class="btn badge rounded-pill bg-success p-3 mx-1" (click)="onReactivate(data)"><i class="fas fa-person-arrow-up-from-line"></i></button>
				}				
			</ng-template>
		</app-grid>
	</div>
</div>