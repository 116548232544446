import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Translation } from '../translations/translate.service';
import { DialogService } from '../../core/services/dialog.service';

@Injectable({
	providedIn: 'root'
})
export class FormGroupHelper {

	private static formGroup: FormGroup;

	public static attachFormGroup(formGroup: FormGroup) {
		this.formGroup = formGroup;
	}

	public static markInvalidFields(formGroup: FormGroup) {
		const controls = formGroup.controls;
		const keys = Object.keys(controls);

		this.markAsDirty(formGroup, keys);
	}

	public static handleServerErrors(errors: {[key: string]: string[]}) {
		if (!this.formGroup) {
			return;
		}
		const errorKeys = Object.keys(errors);

		const formControls = Object.keys(this.formGroup.controls);

		for (const key of errorKeys) {
			if (formControls.includes(key)) {
				this.formGroup.controls[key].setErrors({ 'serverError': errors[key].join(', ') });
				errors[key].push('handled');
			}
		}

		const unhandledErrors = [];
		for (const key of errorKeys) {
			if (!errors[key].includes('handled')) {
				unhandledErrors.push(errors[key].join(', '));
			}
		}

		if (unhandledErrors.length > 0) {			
			DialogService.okDialog(
				Translation.getResource('general.error'),
				unhandledErrors.join('\n')
			);
		}

		this.markAsDirty(this.formGroup, errorKeys);
	}

	private static markAsDirty(formGroup: FormGroup, keys: string[]) {
		for (const key of keys) {
			const control = formGroup.get(key);

			if (control instanceof FormArray) {
				for (const c of (control as FormArray).controls) {
					this.markAsDirty(c as FormGroup, Object.keys(c));
				}
				continue;
			}

			control?.markAsDirty();
		}
	}
}