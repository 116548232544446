import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IdName } from '../../shared/generated';
import { AppComponent } from '../../app.component';

const STORAGE_TOKEN = 'token';
const STORAGE_TENANTS = 'tenants';

@Injectable({
	providedIn: 'root'
})
export class AppAuthService {

	public static setAuthToken(token: string) {
		localStorage.setItem(STORAGE_TOKEN, token);
	}

	public static getAuthToken() {
		return localStorage.getItem(STORAGE_TOKEN) ?? '';
	}

	public static setAvailableTenants(tenants: IdName[]) {
		localStorage.setItem(STORAGE_TENANTS, JSON.stringify(tenants));
	}

	public static getAvailableTenants(): IdName[] {
		return JSON.parse(localStorage.getItem(STORAGE_TENANTS)) as IdName[];
	}
	
	public static isAuthenticated(): boolean {
		const token = this.getAuthToken();
		if (!token) {
			return false;
		}		
		return !new JwtHelperService().isTokenExpired(token);
	}

	public static getUserId(): number {
		const nameIdentifier = new JwtHelperService().decodeToken(this.getAuthToken())[
			'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid'
		];
		return parseInt(nameIdentifier);
	}

	public static getFullName(): string {
		const name = new JwtHelperService().decodeToken(this.getAuthToken())[
			'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
		];
		return name;
	}

	public static getEmail(): string {
		const email = new JwtHelperService().decodeToken(this.getAuthToken())[
			'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
		];
		return email;
	}

	public static getTenantName(): string {
		const tenantName = new JwtHelperService().decodeToken(this.getAuthToken())[
			'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
		] as string;
        
		return tenantName;
	}

	public static getTenantId(): number {
		const tenantId = new JwtHelperService().decodeToken(this.getAuthToken())[
			'http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid'
		];
        
		return Number(tenantId);
	}

	public static isSuperAdmin(): boolean {
		const token = this.getAuthToken();
		if (!token) {
			return false;
		}
		const role = new JwtHelperService().decodeToken(token)[
			'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
		];

		return role === 'SuperAdmin';
	}

	public static logout() {
		localStorage.removeItem(STORAGE_TOKEN);
		localStorage.removeItem(STORAGE_TENANTS);
		AppComponent.app.router.navigate(['/']);
	}
}
