import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
	selector: 'message-dialog',
	templateUrl: './message-dialog.component.html',
	styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {

	public preOkFunction: (() => void) = null;
	public preConfirmFunction: (() => void) = null;
	public preNoFunction: (() => void) = null;
	public preCloseFunction: (() => void) = null;

	@Input()
	public  defaultIndex: number;

	private openedBoolean = false;
	@Input() public title: string;
	@Input() public okTitle: string;
	@Input() public noTitle: string;
	@Output() public okClicked = new EventEmitter<unknown>();
	@Output() public noClicked = new EventEmitter<unknown>();
	@Output() public closeClicked = new EventEmitter<unknown>();
	@Input() public cancelVisible = false;
	@Input() public yesVisible = true;
	@Input() public okDisabled: boolean = false;
	
	private dialogMessage: string;


	public isDisabled = false;

	@Input() public width: string | number = 450;

	@Input() public messageTemplate: TemplateRef<unknown>;

	@Input() public set message (value: string) {
		if (!value) {
			this.dialogMessage = null;
		} else {
			this.dialogMessage = value ?? '';
		}

	}

	public get message (): string {
		return this.dialogMessage;
	}

	public get opened (): boolean {
		return this.openedBoolean;
	}

	@Input()
	public set opened (value: boolean) {
		this.openedBoolean = value;
	}

	constructor(public elementRef: ElementRef) {
	}
	public get templateContext (): unknown {
		return {
			$implicit: this as unknown
		};
	}
	
	public closeClick () {
		if (!this.preCloseFunction === false) {
			this.preCloseFunction();
		} else {
			this.closeAndEmitClose(null);
		}
	}

	public noClick () {
		if (!this.preNoFunction === false) {
			this.preNoFunction();
		} else {
			this.closeAndEmitNo(null);
		}
	}

	/**
     * Object data to emit
     * @param {T} object object data to emit
     * @returns {void}
     */
	public closeAndEmitNo<T> (object: T): void {

		this.noClicked.emit(object);
		this.freeDialog();
	}
	
	/**
	 * Object data to emit
	 * @param {T} object object data to emit
	 * @returns {void}
	 */
	public closeAndEmitClose<T> (object: T): void {
		
		this.closeClicked.emit(object);
		this.freeDialog();
	}

	/**
     * Object data to emit
     * @param {T} object object data to emit
     * @returns {void}
     */
	public closeAndEmitYes<T> (object: T): void {
		this.okClicked.emit(object);
		this.freeDialog();
	}

	/**
     * Object data to emit
     * @param {T} object object data to emit
     * @returns {void}
     */
	public emitYes<T> (object: T): void {
		this.okClicked.emit(object);
	}

	public disableButton() {
		this.isDisabled = true;
	}

	public enableButton() {
		this.isDisabled = false;
	}

	/* Emit ok button clicked event */
	public yesClick () {
		if (!this.preOkFunction === false) {
			this.preOkFunction();
		} else {
			this.closeAndEmitYes(null);
		}
	}

	private freeDialog () {
		this.opened = false;
		this.messageTemplate = null;
		this.isDisabled = false;
		this.preConfirmFunction = null;
		this.preOkFunction = null;
		this.preNoFunction = null;
	}
}
