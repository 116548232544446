<div [formGroup]="formGroup" class="mb-2">
	<div class="d-flex flex-column gap-1">
		<label [class.app-label-required]="isRequired" [for]="label">{{ label }}</label>
		<input  #input				
				pInputText 
				class="w-100"
				[id]="label" 
				[formControlName]="name" 
				appPhoneNumber [applyPhoneValidation]="isPhoneNumber" 
				[ariaLabel]="label" 
				[placeholder]="placeholder ? placeholder : label"
				(change)="onValueChange($event.target['value'])"
		/>
	</div>

    <span class="text-danger"
        *ngIf="formGroup.get(name).invalid && formGroup.get(name).dirty && formGroup.get(name).hasError('required')">
        {{ label }} is required. <br>
    </span>
    <span class="text-danger"
        *ngIf="formGroup.get(name).hasError('email') && formGroup.get(name).dirty && formGroup.get(name).valueChanges">
        Enter a valid email address! <br>
    </span>
    <span class="text-danger"
        *ngIf="formGroup.get(name).hasError('hasNumber') && formGroup.get(name).dirty && formGroup.get(name).valueChanges">
        Password must contain at least one number <br>
    </span>
    <span class="text-danger"
        *ngIf="formGroup.get(name).hasError('hasCapitalCase') && formGroup.get(name).dirty && formGroup.get(name).valueChanges">
        Password must contain at least one capial letter <br>
    </span>
    <span class="text-danger"
        *ngIf="formGroup.get(name).hasError('hasSpecialCharacters') && formGroup.get(name).dirty && formGroup.get(name).valueChanges">
        Password must contain at least one special letter <br>
    </span>
    <span class="text-danger"
        *ngIf="formGroup.get(name).hasError('minlength') && formGroup.get(name).dirty && formGroup.get(name).valueChanges">
        Password must have at least 8 characters <br>
    </span>
    <span class="text-danger"
        *ngIf="formGroup.hasError('matchPassword') && formGroup.get(name).dirty && name === 'confirmPassword'">
        Passwords do not match! <br>
    </span>
    <span class="text-danger" *ngIf="formGroup.get(name).hasError('serverError')">
        {{ formGroup.get(name)['errors']['serverError'] }} <br>
    </span>
</div>