import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TextInputComponent } from '../shared/inputs/text-input/text-input.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslatePipe } from '../shared/translations/translate.pipe';
import { SubmitButtonComponent } from '../shared/inputs/submit-button/submit-button.component';
import { FadeInDirective } from '../shared/directives/fade-in.directive';
import { RouterModule } from '@angular/router';
import { AuthService } from '../shared/generated';

@Component({
	selector: 'app-forgot-password',
	standalone: true,
	imports: [
		CommonModule, 
		TextInputComponent, 
		ReactiveFormsModule, 
		TranslatePipe, 
		SubmitButtonComponent, 
		FadeInDirective,
		RouterModule
	],
	templateUrl: './forgot-password.component.html',
	styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
	public form: FormGroup = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.email])
	});
	public isLoading: boolean = false;
	public errorMessage: string = null;
	public isEmailSent: boolean = false;

	constructor(private authService: AuthService) {

	}

	public onSubmit() {
		if (this.form.invalid) {
			return;
		}

		this.isLoading = true;
		this.authService.forgotPassword({ email: this.form.value.email }).subscribe(
			{
				next: () => {
					this.isLoading = false;
					this.isEmailSent = true;
				},
				error: (error) => {
					this.isLoading = false;
					this.errorMessage = error.error.message;
				} }
		);
	}
}
